import {
  centers,
  childHelpLines,
  nonFormalSchoolIcon,
  // numberCommunity,
  numberHomes,
  openShelters,
} from "../../../assets/images";

export const CenterManagement_cardFixture = [
  {
    imageProps: { alt: "icon", src: centers },
    heading: "19",
    key: "total_centers",
    text: "Total Number of Centers",
  },
  {
    imageProps: { alt: "icon", src: nonFormalSchoolIcon },
    heading: "07",
    key: "Contact Point",
    text: "Number of Contact Points",
  },
  {
    imageProps: { alt: "icon", src: openShelters },
    heading: "03",
    key: "Open Shelter",
    text: "Number of Open Shelters",
  },
  {
    imageProps: { alt: "icon", src: numberHomes },
    heading: "04",
    key: "Home",
    text: "Number of Homes",
  },
  // {
  //   imageProps: { alt: "icon", src: numberCommunity },
  //   heading: "22",
  //   key: "Contact Point",
  //   text: "Number of Community Contact Points",
  // },
  {
    imageProps: { alt: "icon", src: childHelpLines },
    heading: "01",
    key: "Child Help Line",
    text: "Number of Child Help Lines",
  },
];

export const tableHeadersFixture = [
  { text: "S No.", key: "serial_num" },
  { text: "Center Type", key: "centertypeId" },
  { text: "Center Name", key: "name" },
  { text: "Center Email Id", key: "email" },
  { text: "Address", key: "address" },
  { text: "Phone No.", key: "phone" },
  { text: "Status", key: "status", val: false },
  { text: "Action", action: true, key: ["edit", "delete"] },
];

export const centerDetailsFixture = {
  centertypeId: "",
  name: "",
  email: "",
  phone: "",
  address: "",
  status: "1",
  prefix: "",
};

export const filterDetailsFixtire = {
  centertypeId: "",
  name: "",
};

export const TagsTableHeaderFixture = [
  { text: "S No.", key: "serial_num" },
  { text: "Tag Name", key: "name" },
  { text: "Created Date Time", key: "created_at", formatDate: true },
  { text: "Action", action: true, key: ["edit", "delete"] },
];

export const tagDetailsFixture = {
  name: "",
};

export const EducationTableHeaderFixture = [
  { text: "S No.", key: "serial_num" },
  { text: "Educational Date", key: "transfer_date" },
  { text: "Center Name", objkey: "center_data", key: "name" },
  { text: "Name", objkey: "child_data", key: "name" },
  { text: "Comments", key: "comment" },
  { text: "Education Type", key: "education_type" },
  { text: "Created Date-Time", key: "created_at", formatDate: true },
  { text: "Modified Date-Time", key: "updated_at", formatDate: true },

  { text: "Action", action: true, key: ["delete"] },
];

export const beneficiaryFormFixture = (fixtureFor, optionData) => {
  let arr = [
    {
      inputProps: {
        type: "searchableSelect",
        min_inputlength: 2,
        className: "form-control",
        placeholder: "Select",
        name: "childregId",
      },
      className: "col-12 col-sm-6",
      parentClassName: "row",
      optionProps: { textKey: "name", valueKey: "id" },
      options: optionData?.childrens ? [...optionData.childrens] : [],
      label: {
        text: "Child",
        labelProps: { htmlFor: "childregId", className: "" },
      },
      row: "new",
    },
    {
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select",
        name: "centerId",
      },
      parentClassName: "row mb-3",
      className: "col-12 col-sm-6",
      options: optionData?.centerNames ? [...optionData.centerNames] : [],
      optionProps: { textKey: "name", valueKey: "id" },
      label: {
        text: "Center Name",
        labelProps: { htmlFor: "centerId", className: "" },
      },
    },
  ];
  if (fixtureFor === "education") {
    arr.push({
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select",
        name: "education_type",
      },
      className: "col-12 col-sm-6",
      options: optionData?.education_list ? [...optionData.education_list] : [],
      label: {
        text: "Education",
        labelProps: { htmlFor: "education_type", className: "" },
      },
    });
  } else if (fixtureFor === "medical") {
    arr.push({
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select",
        name: "medical_issues",
      },
      className: "col-12 col-sm-6",
      options: optionData?.medical_list ? [...optionData.medical_list] : [],
      label: {
        text: "Medical Issues",
        labelProps: { htmlFor: "medical_issues", className: "" },
      },
    });
  } else if (fixtureFor === "mhp") {
    arr.push(
      {
        inputProps: {
          type: "select",
          className: "form-control",
          placeholder: "Select",
          name: "mhp",
        },
        className: "col-12 col-sm-6",
        options: optionData?.mhp_list ? [...optionData.mhp_list] : [],
        label: {
          text: "Mhp",
          labelProps: { htmlFor: "mhp", className: "" },
        },
      },
      {
        inputProps: {
          type: "select",
          className: "form-control",
          placeholder: "Select",
          name: "other",
        },
        className: "col-12 col-sm-6",
        options: optionData?.other_list ? [...optionData.other_list] : [],
        label: {
          text: "Other",
          labelProps: { htmlFor: "other", className: "" },
        },
      }
    );
  }

  return [
    ...arr,
    {
      inputProps: {
        type: "text",
        isdatefield: true,
        className: "form-control border-right-0",
        placeholder: "DD-MM-YY",
        name: "transfer_date",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "Transfer Date",
        labelProps: { htmlFor: "transfer_date", className: "" },
      },
    },
    {
      inputProps: {
        type: "textarea",
        className: "form-control",
        placeholder: "Write here ...",
        name: "comment",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Comment",
        labelProps: { htmlFor: "comment", className: "" },
      },
    },
  ];
};

export const beneficiaryInputFixture = (fixtureFor, b_type) => ({
  childregId: "",
  centerId: "",
  transfer_date: "",
  comment: "",
  ...(fixtureFor === "education"
    ? { education: b_type === "registered" ? 1 : 0, education_type: "" }
    : fixtureFor === "mhp"
    ? { is_mhp: b_type === "registered" ? 1 : 0, mhp: "", other: "" }
    : { medical: b_type === "registered" ? 1 : 0, medical_issues: "" }),
});

export const MedicalTableHeaderFixture = [
  { text: "S No.", key: "serial_num" },
  { text: "Medical Date", key: "transfer_date" },
  { text: "Center Name", objkey: "center_data", key: "name" },
  { text: "Name", objkey: "child_data", key: "name" },
  { text: "Comments", key: "comment" },
  { text: "Medical Issue", key: "medical_issues" },
  { text: "Created Date-Time", key: "created_at", formatDate: true },
  { text: "Modified Date-Time", key: "updated_at", formatDate: true },
  { text: "Action", action: true, key: ["delete"] },
];

export const mhpTableHeaderFixture = [
  { text: "S No.", key: "serial_num" },
  { text: "Date of Entry", key: "transfer_date" },
  { text: "Center Name", objkey: "center_data", key: "name" },
  { text: "Mhp", key: "mhp" },
  { text: "Comments", key: "comment" },
  { text: "Other Info", key: "other" },
  { text: "Created Date-Time", key: "created_at", formatDate: true },
  { text: "Uploaded By", objkey: "user_data_history", key: "name" },
];

export const mealListTableFixture = [
  { text: "S No.", key: "serial_num" },
  { text: "Center Name", key: "center_name" },
  { text: "Child Count", key: "child_count" },
  { text: "Meals", key: "meals" },
];

export const educationListFixture = [
  { text: "S No.", key: "serial_num" },
  { text: "Name", objkey: "child_data", key: "name" },
  { text: "Center Name", objkey: "center_data", key: "name" },
  { text: "Comments", key: "comment" },
  { text: "Education Type", key: "education_type", formatVal: true },
  { text: "Educational Date", key: "transfer_date" },
  { text: "Created Date-Time", key: "created_at", formatDate: true },
  { text: "Created By", objkey: "user_data_history", key: "name" },
];

export const medicalListFixture = [
  { text: "S No.", key: "serial_num" },
  { text: "Name", objkey: "child_data", key: "name" },
  { text: "Center Name", objkey: "center_data", key: "name" },
  { text: "Comments", key: "comment" },
  { text: "Medical Issue", key: "medical_issues", formatVal: true },
  { text: "Medical Date", key: "transfer_date" },
  { text: "Created Date-Time", key: "created_at", formatDate: true },
  { text: "Created By", objkey: "user_data_history", key: "name" },
];

export const mhpListFixture = [
  { text: "S No.", key: "serial_num" },
  { text: "Name", objkey: "child_data", key: "name" },
  { text: "Center Name", objkey: "center_data", key: "name" },
  { text: "Mhp", key: "mhp", formatVal: true },
  { text: "Comments", key: "comment" },
  { text: "Other Info", key: "other" },
  { text: "Date of Entry", key: "transfer_date" },
  { text: "Created Date-Time", key: "created_at", formatDate: true },
  { text: "Created By", objkey: "user_data_history", key: "name" },
];
