import React, { useEffect, useState } from "react";
// import { favicon } from "../../assets/images";
import ProfileMenu from "./ProfileMenu";
import Notifications from "./Notifications";
import { useLocation } from "react-router-dom";
import { headerTextFixture } from "../../utilities/fixtures";

const Header = () => {
  const location = useLocation();
  const [heading, setHeading] = useState("");
  const [pageTitle, setPageTitle] = useState("SBT Admin");

  useEffect(() => {
    document.title = pageTitle ? `SBT Admin | ${pageTitle}` : "SBT Admin";
  }, [pageTitle]);

  useEffect(() => {
    let pathData = headerTextFixture.filter(
      (item) => item.link === location.pathname
    );
    if (pathData?.length) {
      setHeading(pathData[0]?.text);
      setPageTitle(
        pathData[0]?.pageTitle ? pathData[0].pageTitle : pathData[0]?.text
      );
    }

    // const preload = document.getElementsByClassName("preloader")[0];
    // const logoImg = document.getElementsByClassName("animation__shake")[0];
    // if (preload) preload.style = "height : 100vh";
    // if (logoImg) logoImg.style = "display : block";

    // setTimeout(() => {
    //   const preload = document.getElementsByClassName("preloader")[0];
    //   const logoImg = document.getElementsByClassName("animation__shake")[0];
    //   if (preload) preload.style = "height : 0px";
    //   if (logoImg) logoImg.style = "display : none";
    // }, 500);
  }, [location.pathname]);

  return (
    <div className="wrapper">
      {/* <div className="preloader flex-column justify-content-center align-items-center">
        <img className="animation__shake" src={favicon} alt="Logo" />
      </div> */}

      <nav className="main-header navbar navbar-expand navbar-white navbar-light py-3 py-lg-4">
        {/* <!-- Left navbar links --> */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <span
              className="nav-link py-0 h-100"
              data-widget="pushmenu"
              role="button"
            >
              <i className="fas fa-bars"></i>
            </span>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <span className="nav-link page-title py-0 h-100">
              {heading ? heading : "Dashboard"}
            </span>
          </li>
        </ul>
        {/* <!-- Left navbar links -->
            <!-- Right navbar links --> */}
        <ul className="navbar-nav ml-auto align-items-center">
          {/* <!-- Notifications Dropdown Menu --> */}
          <li className="nav-item dropdown">
            <Notifications />
          </li>
          <li className="nav-item">
            <ProfileMenu />
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Header;
