import React, { Fragment, useEffect, useState } from "react";
import { downIcon } from "../../assets/images";
import {
  addProduceFormFixture,
  addProduceInputFixture,
  addReferFormFixture,
  addReferInputFixture,
  docsFormFixture,
} from "./utilities/fixtures";
import Form from "../../components/Form/Form";
import { restoreDocsInputFormFixture } from "../ChildRegistration/utilities/fixtures";
import {
  getCenterNames,
  getTagNames,
  isActionAllowed,
  onChangeInputData,
} from "../../utilities/helpers";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import validate from "../../utilities/validation";
import { postDataWithAuth } from "../../_api/apiCall";
import { endpoints } from "../../_api/endpoints";

const AddProduce = () => {
  const location = useLocation();
  const child_data = location.state;

  const [docs, setDocs] = useState([restoreDocsInputFormFixture]);
  const [formFixture, setFormFixture] = useState([]);
  const [inputs, setInputs] = useState({});
  const [centerNames, setCenterNames] = useState([]);
  const [produce, setProduce] = useState(false);
  const [docFormFixture, setDocFormFixture] = useState(docsFormFixture());
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const addMoreDocuments = () => {
    let c1 = 0;
    for (let i = 0; i < docs.length; i++) {
      if (docs[i]["tagId"]) c1++;
    }
    if (c1 === docs.length) setDocs([...docs, restoreDocsInputFormFixture]);
  };

  const handleDocInputChange = (e, ind) => {
    onChangeInputData(e, docs, setDocs, {
      index: ind,
    });
  };

  const handleSubmit = async () => {
    if (loading || !child_data?.id) return;
    if (handleValidation()) {
      let formData = new FormData();
      let centerid =
        child_data?.center_data[child_data?.center_data?.length - 1]?.["id"];
      formData.append("id", child_data.id);
      if (centerid) formData.append("centerId", centerid);

      for (let key in inputs) {
        formData.append(key, inputs[key]);
      }
      let ind = 0;
      for (let key in docs) {
        const doc = docs[key];
        if (!doc["id"]) {
          for (let key2 in doc) {
            formData.append(`documents[${ind}][${key2}]`, doc[key2]);
          }
          ind++;
        }
      }
      const { success, message } = await postDataWithAuth({
        api_url: produce
          ? endpoints["addProduceToCWC"]
          : endpoints["addReferToCWC"],
        data: formData,
        contentType: "multipart/form-data",
      });
      setLoading(false);
      if (success) {
        if (message) toast.success(message);
        navigate(-1);
      } else {
        toast.error("Something went wrong!");
      }
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleValidation = () => {
    let validation = true;
    let transferDateLabel = "Produce Date";
    if (!produce) {
      transferDateLabel = "Refer Date";
    }
    for (let key in inputs) {
      let err =
        key === "transfer_date"
          ? validate[key](inputs[key], transferDateLabel)
          : validate[key](inputs[key]);

      if (!!err) {
        toast.error(err);
        return false;
      }
    }

    for (let i = 0; i < docs.length; i++) {
      if (produce) continue;
      for (let key in docs[i]) {
        let err = validate[key](docs[i][key]);
        if (!!err) {
          toast.error(err);
          return false;
        }
      }
    }

    return validation;
  };

  useEffect(() => {
    async function fetchData() {
      const tagData = await getTagNames();
      const centerData = await getCenterNames();
      if (tagData)
        setDocFormFixture(
          docsFormFixture(
            { tagNames: tagData },
            location.pathname === "/produce/add"
          )
        );
      if (centerData) setCenterNames(centerData);
    }
    fetchData();
    if (!isActionAllowed("create", "cwc")) {
      navigate(-1);
    }
  }, [navigate]);

  useEffect(() => {
    if (location.pathname === "/produce/add") {
      setFormFixture(addProduceFormFixture);
      setInputs(addProduceInputFixture);
      setProduce(true);
    } else if (location.pathname === "/refer/add") {
      setFormFixture(addReferFormFixture({ centerNames: centerNames }));
      setInputs(addReferInputFixture);
      setProduce(false);
    }
  }, [location.pathname, centerNames]);

  return (
    <>
      <main className="inner">
        <div className="bg-white mb-3">
          <div className="px-4 py-3 border-bottom header-page">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="d-flex align-items-center mb-3 mb-md-0">
                  <Link
                    to={produce ? "/produce" : "/refer"}
                    className=" btn btn-back"
                  >
                    <img src={downIcon} alt="" />
                  </Link>
                  <h5 className="page-title mb-0 pl-3">
                    {produce ? "Add CWC Produce" : "Add CWC Refer"}
                  </h5>
                </div>
              </div>
            </div>
          </div>

          <div className="p-4">
            <div className="registration-form">
              <Form
                fixture={formFixture}
                inputs={inputs}
                showNextBtn={false}
                onChange={(e) => onChangeInputData(e, inputs, setInputs)}
                loading={loading}
              />
            </div>
          </div>
        </div>

        <div className="bg-white mb-3">
          <div className="px-4 py-3 border-bottom header-page">
            <div className="row align-items-center">
              <div className="col-12 col-md-6">
                <div className="d-flex align-items-center  mb-3 mb-md-0">
                  <Link
                    to={produce ? "/produce" : "/refer"}
                    className=" btn btn-back"
                  >
                    <img src={downIcon} alt="" />
                  </Link>
                  <h5 className="page-title mb-0 pl-3">Add Documents</h5>
                </div>
              </div>
              <div className="col-12  col-md-6">
                <div className="button-group filter-button justify-content-md-end">
                  <span
                    className="btn btn-primary btn-add"
                    onClick={addMoreDocuments}
                  >
                    Add More
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="p-4">
            <div className="registration-form">
              {docs.map((docInput, key) => (
                <Fragment key={key}>
                  <Form
                    fixture={docFormFixture}
                    inputs={docInput}
                    loading={loading}
                    onChange={(e) => handleDocInputChange(e, key)}
                    onSubmit={handleSubmit}
                    nextBtnText="Submit"
                    showNextBtn={key === docs.length - 1}
                    showBackBtn={key === docs.length - 1}
                    backBtnText="Back"
                    backBtnClasses="btn-primary-border"
                    handleBack={handleBack}
                  ></Form>
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddProduce;
