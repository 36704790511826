import { getFileExtension } from "./helpers";

export const emailId = (val) => {
  if (!val) return "Please enter an email";
  else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(val))
    return "Invalid email";
  return null;
};

export const email = (val) => {
  return emailId(val);
};

export const password = (val) => {
  if (!val) return "Please enter password";
};

export const noValidation = (val) => {
  return null;
};

export const isValidPhone = (val) => {
  if (isNaN(val)) return "Please enter valid phone number";
  else if (val.length !== 10) return "Phone number must be of 10 digits";
};

export const isValidName = (val) => {
  if (!val) return "Please enter name";
};

export const status = (val) => {
  if (isEmpty(val)) return "The status field is required.";
};

export const comment = (val) => {
  if (isEmpty(val)) return "The comment field is required.";
};

export const role_id = (val) => {
  if (isEmpty(val)) return "The Role field is required.";
};

export const centertypeId = (val) => {
  if (isEmpty(val)) return "The Center field is required.";
};

export const centerId = (val, label) => {
  if (isEmpty(val) && label) return `The ${label} field is required`;
  else if (isEmpty(val)) return "The Center Name field is required.";
};

export const current_centerId = (val) => {
  if (isEmpty(val)) return "The Current Center Name field is required.";
};

export const education_type = (val) => {
  if (isEmpty(val)) return "The Education field is required.";
};

export const transfer_date = (val, label = false) => {
  if (label && isEmpty(val)) return `The ${label} field is required.`;
  else if (isEmpty(val)) return "The Transfer Date field is required.";
  validateDate(val);
};

export const date = (val) => {
  if (isEmpty(val)) return "The Date field is required.";
  validateDate(val);
};

export const childregId = (val) => {
  if (isEmpty(val)) return "The Child field is required.";
};

export const medical_issues = (val) => {
  if (isEmpty(val)) return "The Medical Issue field is required.";
};

export const other = (val) => {
  if (isEmpty(val)) return "The Others field is required.";
};

export const restoration_method = (val) => {
  if (isEmpty(val)) return "The Restoration Method field is required.";
};

export const restore_to_address = (val) => {
  if (isEmpty(val)) return "The Restoration Address field is required.";
};

export const restore_to = (val) => {
  if (isEmpty(val)) return "The Restored To field is required.";
};

export const mhp = (val) => {
  if (isEmpty(val)) return "The MHP field is required.";
};

export const education_level = (val) => {
  if (isEmpty(val)) return "The Education Level field is required.";
};

export const tagId = (val) => {
  if (isEmpty(val)) return "The Tag Name field is required.";
};

export const doc_date = (val) => {
  if (isEmpty(val)) return "The Document's Date field is required.";
  validateDate(val);
};

export const escort_exit_address = (val) => {
  if (isEmpty(val)) return "The Escort/Exit Address field is required.";
};

export const escort_exit_to = (val) => {
  if (isEmpty(val)) return "The Escort/Exit To field is required.";
};

export const escort_exit_method = (val) => {
  if (isEmpty(val)) return "The Exit Method field is required.";
};

export const exit_type = (val) => {
  if (isEmpty(val)) return "The Exit Type field is required.";
};

export const cwccaseno = (val) => {
  if (isEmpty(val)) return "The CWC Case No. field is required.";
};

export const documents = (val) => {
  if (!val) return "Documents is required.";
  validateFile(val);
};

export const aadharno = (val) => {
  if (!val) return "Aadhar No. is required";
  else if (val.length !== 12) return "Please Enter Valid Aadhar No.";
};

export const gender = (val) => {
  if (!val) return "Gender is required";
};

export const dateofarrival = (val) => {
  return transfer_date(val, "Arrival Date");
};

export const ReferredBy = (val) => {
  if (!val) return "Reffered By is required";
};

export const followupmethod = (val) => {
  if (!val) return "Follow-Up Method is required";
};

export const image = (val) => {
  if (val && !validateFile(val, true)) {
    const fileExtension = getFileExtension(val?.name);
    const allowedExtensions = ["jpeg", "png", "jpg"];
    if (!allowedExtensions.includes(fileExtension)) {
      return `Only ${allowedExtensions
        .toString()
        .replaceAll(",", " ,")} files are allowed.`;
    }
  }
};

export const isEmpty = (val) => {
  return val == null || (typeof val === "string" && val.trim().length === 0);
};

export const validateDate = (dateStr) => {
  if (isEmpty(dateStr)) return "Invalid Date";
  else if (isNaN(new Date(dateStr))) return "Invalid Date";
};

export const validateFile = (file, isImage = false) => {
  if (!file?.size) return "Invalid File";
  else {
    let sizeInMB = file.size / (1024 * 1024);
    let msg = isImage
      ? "The Image must not be greater than 5 mb"
      : "File can be uploaded, with up to 5 MB size.";
    if (sizeInMB > 5) return msg;
  }
};

const validate = {
  emailId,
  password,
  email,
  phone: isValidPhone,
  name: isValidName,
  status,
  mobile_number: isValidPhone,
  join_date: noValidation,
  image,
  comment,
  role_id,
  centertypeId,
  education: noValidation,
  childregId,
  centerId,
  education_type,
  transfer_date,
  medical_issues,
  medical: noValidation,
  documents,
  other,
  restoration_method,
  restore_to_address,
  restore_to,
  mhp,
  education_level,
  tagId,
  doc_date,
  filename: documents,
  current_centerId,
  escort_exit_address,
  escort_exit_to,
  escort_exit_method,
  exit_type,
  cwccaseno,
  is_mhp: noValidation,
  date,
  register_type: noValidation,
  id: noValidation,
  mobileno: isValidPhone,
  phoneno: isValidPhone,
  aadharno,
  gender,
  dateofarrival,
  ReferredBy,
  centerdetailId: noValidation,
  followupmethod,
};

export default validate;
