import {
  activity,
  // childProfile,
  documentIcon,
  followUpIcon,
  graduationHatIcon,
  homeIcon,
  infoIcon,
  medicalKitIcon,
  whatsappStatusIcon,
} from "../../../assets/images";

export const UserTableHeaderFixture = (select = false) => {
  let arr = [];
  arr.push({ text: "SL No.", key: "serial_num" });
  if (select) arr.push({ text: "Bulk Update Check Box", key: "select_row" });
  return [
    ...arr,
    { text: "Child Name", key: "name", profile: true },
    { text: "SBT Unique ID", key: "uniqueId" },
    { text: "Centre ID", objkey: "center_data", key: "unique_centerId" },
    { text: "Aadhar No.", key: "aadharno" },
    { text: "CWC Case No.", key: "cwccaseno" },
    { text: "Gender", key: "gender" },
    { text: "Age", key: "age" },
    { text: "Present Centre Name", objkey: "center_data", key: "name" },
    { text: "Father Name", key: "f_name" },
    { text: "State", objkey: "state_data", key: "name" },
    { text: "District", objkey: "district_data", key: "name" },
    { text: "Address", key: "address" },
    { text: "Phone No.", key: "mobileno" },
    {
      text: "Short Stay Center Name",
      key: "short_stay",
      fn: (item) =>
        item?.short_stay_status === "yes" && item?.short_stay
          ? item.short_stay
          : "-",
    },
    { text: "Arrival date", key: "dateofarrival" },
    { text: "Registered By", objkey: "user_data", key: "name" },
    { text: "Modified By", objkey: "user_data", key: "name" },
    { text: "Created Date Time", key: "created_at", formatDate: true },
    { text: "Modified Date-Time", key: "updated_at", formatDate: true },
    { text: "Present Status", objkey: "center_data", key: "history_status" },
    {
      text: "Action",
      action: true,
      key: ["view", "edit", "delete"],
      actionFor: "child_registration",
    },
  ];
};

export const FilterFormFixture = (optionData) => {
  return [
    {
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select",
        name: "centerdetailId",
      },
      parentClassName: "row mb-3",
      className: "col-12 col-sm-6",
      options: optionData?.centerNames ? [...optionData.centerNames] : [],
      optionProps: { textKey: "name", valueKey: "id" },
      label: {
        text: "Center Name",
        labelProps: { htmlFor: "centerdetailId", className: "" },
      },
      readOnlyField: true,
      row: "new",
    },
    {
      inputProps: {
        type: "text",
        className: "form-control",
        placeholder: "Enter Child Name",
        name: "name",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "Child Name",
        labelProps: { htmlFor: "name", className: "" },
      },
    },
    {
      inputProps: {
        type: "text",
        className: "form-control",
        placeholder: "Enter Unique Id",
        name: "uniqueId",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "Unique Id",
        labelProps: { htmlFor: "uniqueId", className: "" },
      },
    },
    {
      inputProps: {
        type: "text",
        className: "form-control",
        placeholder: "Enter CWC Case No.",
        name: "cwccaseno",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "CWC Case No.",
        labelProps: { htmlFor: "cwccaseno", className: "" },
      },
    },
    {
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select a Gender",
        name: "gender",
      },
      className: "col-12 col-sm-6",
      options: [{ text: "Male" }, { text: "Female" }],
      label: {
        text: "Gender",
        labelProps: { htmlFor: "gender", className: "" },
      },
    },
    {
      inputProps: {
        type: "text",
        className: "form-control",
        placeholder: "Enter Phone Number",
        name: "phoneno",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "Phone Number",
        labelProps: { htmlFor: "phoneno", className: "" },
      },
    },

    {
      inputProps: {
        type: "searchableSelect",
        className: "form-control",
        placeholder: "Select Country",
        name: "countryId",
      },
      className: "col-12 col-sm-6",
      parentClassName: "row",
      optionProps: { textKey: "name", valueKey: "id" },
      options: optionData?.countries ? [...optionData.countries] : [],
      label: {
        text: "Country",
        labelProps: { htmlFor: "countryId", className: "" },
      },
      row: "new",
    },
    {
      inputProps: {
        type: "searchableSelect",
        className: "form-control",
        placeholder: "Select State",
        name: "stateId",
      },
      className: "col-12 col-sm-6",
      optionProps: { textKey: "name", valueKey: "id" },
      options: optionData?.states ? [...optionData.states] : [],
      label: {
        text: "State",
        labelProps: { htmlFor: "stateId", className: "" },
      },
    },
    {
      inputProps: {
        type: "searchableSelect",
        className: "form-control",
        placeholder: "Select District",
        name: "district",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      options: optionData?.district ? [...optionData.district] : [],
      optionProps: { textKey: "name", valueKey: "id" },
      label: {
        text: "District",
        labelProps: { htmlFor: "district", className: "" },
      },
    },
    {
      inputProps: {
        type: "searchableSelect",
        className: "form-control",
        placeholder: "Select City",
        name: "cityId",
      },
      className: "col-12 col-sm-6",
      options: optionData?.cities ? [...optionData.cities] : [],
      optionProps: { textKey: "name", valueKey: "id" },
      label: {
        text: "City",
        labelProps: { htmlFor: "cityId", className: "" },
      },
    },
    // {
    //   inputProps: {
    //     type: "select",
    //     className: "form-control",
    //     name: "",
    //   },
    //   className: "col-12 col-sm-6",
    //   // options: optionData?.cities ? [...optionData.cities] : [],
    //   // optionProps: { textKey: "name", valueKey: "id" },
    //   label: {
    //     text: "Special Category",
    //     labelProps: { htmlFor: "", className: "" },
    //   },
    // },
    // {
    //   inputProps: {
    //     type: "select",
    //     className: "form-control",
    //     name: "child_status",
    //   },
    //   className: "col-12 col-sm-6",
    //   options: [
    //     { text: "Produced to CWC", value: "Produced_CWC" },
    //     { text: "Referred from CWC", value: "Referred_CWC" },
    //     { text: "New Corner", value: "New_Corner" },
    //   ],
    //   // optionProps: { textKey: "name", valueKey: "id" },
    //   label: {
    //     text: "Select Status",
    //     labelProps: { htmlFor: "child_status", className: "" },
    //   },
    // },
    {
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select",
        name: "short_stay_status",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      options: [
        { text: "Yes", value: "yes" },
        { text: "No", value: "no" },
      ],
      label: {
        text: "Short Stay",
        labelProps: { htmlFor: "short_stay_status", className: "" },
      },
    },

    {
      inputProps: {
        type: "text",
        isdatefield: true,
        className: "form-control border-right-0",
        placeholder: "DD-MM-YY",
        name: "from_date",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "From",
        labelProps: { htmlFor: "from_date", className: "" },
      },
    },
    {
      inputProps: {
        type: "text",
        isdatefield: true,
        className: "form-control border-right-0",
        placeholder: "DD-MM-YY",
        name: "to_date",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "To",
        labelProps: { htmlFor: "to_date", className: "" },
      },
    },
    {
      inputProps: {
        type: "text",
        isdatefield: true,
        className: "form-control border-right-0",
        placeholder: "DD-MM-YY",
        name: "dateofarrival",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "Arrival Date",
        labelProps: { htmlFor: "dateofarrival", className: "" },
      },
    },
  ];
};

export const filterInputFixture = (data) => ({
  centerdetailId: data?.centerId || "",
  name: "",
  gender: "",
  countryId: "",
  stateId: "",
  district: "",
  cityId: "",
  dateofarrival: "",
  from_date: "",
  to_date: "",
  uniqueId: "",
  cwccaseno: "",
  phoneno: "",
  // child_status:"",
  short_stay_status: "",
});

export const childProfileNavFixture = [
  {
    imageProps: { alt: "", src: infoIcon },
    link: "#",
    text: "Child’s Information",
  },
  {
    imageProps: { alt: "", src: activity },
    link: "#",
    text: "Activity Log",
  },
  {
    imageProps: { alt: "", src: documentIcon },
    link: "#",
    text: "Documents",
  },
  {
    imageProps: { alt: "", src: whatsappStatusIcon },
    link: "#",
    text: "Centers",
  },
  {
    imageProps: { alt: "", src: followUpIcon },
    link: "#",
    text: "Restoration Follow-Up",
  },
  {
    imageProps: { alt: "", src: followUpIcon },
    link: "#",
    text: "Follow-Ups",
  },
  {
    imageProps: { alt: "", src: medicalKitIcon },
    link: "#",
    text: "Medical",
  },
  {
    imageProps: { alt: "", src: graduationHatIcon },
    link: "#",
    text: "Education",
  },
  {
    imageProps: { alt: "", src: homeIcon },
    link: "#",
    text: "MHP",
  },
];

export const overviewFormFixture = (action) => [
  {
    inputProps: {
      type: "text",
      className: "form-control",
      placeholder: "Name",
      name: "name",
    },
    className: "col-12 col-sm-6",
    parentClassName: "row",
    parentProps: { input: { className: "form-group mb-4" } },
    label: {
      text: "Name",
      labelProps: { htmlFor: "name", className: "" },
    },
    required: true,
    row: "new",
  },
  {
    inputProps: {
      type: "select",
      className: "form-control",
      placeholder: "Select a Gender",
      name: "gender",
    },
    className: "col-12 col-sm-6",
    parentProps: { input: { className: "form-group mb-4" } },
    options: [{ text: "Male" }, { text: "Female" }],
    label: {
      text: "Gender",
      labelProps: { htmlFor: "gender", className: "" },
    },
    required: true,
  },
  {
    inputProps: {
      type: "text",
      isdatefield: true,
      className: "form-control border-right-0",
      placeholder: "DD-MM-YY",
      name: "dateofarrival",
    },
    className: "col-12 col-sm-6",
    label: {
      text: "Arrival Date",
      labelProps: { htmlFor: "dateofarrival", className: "" },
    },
    required: true,
  },
  ...(action === "edit" || action === "view"
    ? [
        {
          inputProps: {
            type: "text",
            isdatefield: true,
            className: "form-control border-right-0",
            placeholder: "DD-MM-YY",
            name: "center_transfer_date",
          },
          className: "col-12 col-sm-6",
          label: {
            text: "Center Arrival Date",
            labelProps: { htmlFor: "center_transfer_date", className: "" },
          },
          required: true,
          readOnlyField: true,
        },
      ]
    : []),
];

export const basicInfoFormFixture = (optionData) => {
  return [
    // {
    //   inputProps: {
    //     type: "select",
    //     className: "form-control",
    //     name: "family_touch",
    //   },
    //   className: "col-12 col-sm-6",
    //   parentClassName: "row",
    //   parentProps: { input: { className: "form-group mb-4" } },
    //   options: [{ text: "Yes" }, { text: "No" }],
    //   label: {
    //     text: "Is the child in touch with family?",
    //     labelProps: { htmlFor: "family_touch", className: "" },
    //   },
    //   row: "new",
    // },
    {
      radioGroup: true,
      groupLabel: {
        text: "Is the child in touch with family?",
        labelProps: { className: "w-100 d-block" },
      },
      parentClassName: "row",
      row: "new",
      className: "col-12 col-sm-6",
      radioFields: [
        {
          inputProps: {
            type: "radio",
            className: "form-check-input",
            name: "family_touch",
            id: "family_touch_yes",
            value: "yes",
          },
          parentProps: { input: { className: "form-check mr-5" } },
          label: {
            text: "Yes",
            props: {
              htmlFor: "family_touch_yes",
              className: "form-check-label",
            },
          },
        },
        {
          inputProps: {
            type: "radio",
            className: "form-check-input",
            id: "family_touch_no",
            name: "family_touch",
            value: "no",
          },
          parentProps: { input: { className: "form-check" } },
          label: {
            text: "No",
            props: {
              htmlFor: "family_touch_no",
              className: "form-check-label",
            },
          },
        },
      ],
    },
    {
      inputProps: {
        type: "text",
        className: "form-control",
        placeholder: "Father’s Name",
        name: "f_name",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Father’s Name",
        labelProps: { htmlFor: "f_name", className: "" },
      },
    },
    {
      inputProps: {
        type: "text",
        className: "form-control",
        placeholder: "Mother’s Name",
        name: "m_name",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Mother’s Name",
        labelProps: { htmlFor: "m_name", className: "" },
      },
    },
    {
      inputProps: {
        type: "text",
        className: "form-control",
        placeholder: "Occupation",
        name: "occupation",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Occupation of Parents",
        labelProps: { htmlFor: "occupation", className: "" },
      },
    },
    // {
    //   inputProps: {
    //     type: "text",
    //     className: "form-control",
    //     placeholder: "Income",
    //     name: "income",
    //   },
    //   className: "col-12 col-sm-6",
    //   parentProps: { input: { className: "form-group mb-4" } },
    //   label: {
    //     text: "Income",
    //     labelProps: { htmlFor: "income", className: "" },
    //   },
    // },
    {
      inputProps: {
        type: "text",
        className: "form-control",
        placeholder: "Number of Siblings",
        name: "no_sibling",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Number of Siblings",
        labelProps: { htmlFor: "no_sibling", className: "" },
      },
    },
    {
      inputProps: {
        type: "text",
        className: "form-control",
        placeholder: "Address",
        name: "address",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Address",
        labelProps: { htmlFor: "address", className: "" },
      },
    },
    {
      inputProps: {
        type: "text",
        className: "form-control",
        placeholder: "Age",
        name: "age",
      },
      className: "col-12 col-sm-6",
      parentClassName: "row",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Age",
        labelProps: { htmlFor: "age", className: "" },
      },
      row: "new",
    },
    {
      inputProps: {
        type: "text",
        isdatefield: true,
        className: "form-control border-right-0",
        placeholder: "DD-MM-YY",
        name: "dob",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "Date of Birth",
        labelProps: { htmlFor: "dob", className: "" },
      },
    },
    // {
    //   inputProps: {
    //     type: "text",
    //     className: "form-control",
    //     placeholder: "Caste",
    //     name: "caste",
    //   },
    //   className: "col-12 col-sm-4",

    //   parentProps: { input: { className: "form-group mb-4" } },
    //   label: {
    //     text: "Caste",
    //     labelProps: { htmlFor: "caste", className: "" },
    //   },
    // },

    {
      inputProps: {
        type: "searchableSelect",
        className: "form-control",
        placeholder: "Select Country",
        name: "countryId",
      },
      className: "col-12 col-sm-6",
      parentClassName: "row",
      parentProps: { input: { className: "form-group mb-4" } },
      optionProps: { textKey: "name", valueKey: "id" },
      options: optionData?.countries ? [...optionData.countries] : [],
      label: {
        text: "Country",
        labelProps: { htmlFor: "countryId", className: "" },
      },
      row: "new",
    },
    {
      inputProps: {
        type: "searchableSelect",
        className: "form-control",
        placeholder: "Select State",
        name: "stateId",
      },
      className: "col-12 col-sm-6",
      optionProps: { textKey: "name", valueKey: "id" },
      parentProps: { input: { className: "form-group mb-4" } },
      options: optionData?.states ? [...optionData.states] : [],
      label: {
        text: "State",
        labelProps: { htmlFor: "stateId", className: "" },
      },
    },
    optionData?.countryId && String(optionData?.countryId) !== "101"
      ? {
          inputProps: {
            type: "searchableSelect",
            className: "form-control",
            placeholder: "Select City",
            name: "cityId",
          },
          className: "col-12 col-sm-6",
          parentProps: { input: { className: "form-group mb-4" } },
          options: optionData?.cities ? [...optionData.cities] : [],
          optionProps: { textKey: "name", valueKey: "id" },
          label: {
            text: "City",
            labelProps: { htmlFor: "cityId", className: "" },
          },
        }
      : {
          inputProps: {
            type: "searchableSelect",
            className: "form-control",
            placeholder: "Select District",
            name: "district",
          },
          className: "col-12 col-sm-6",
          parentProps: { input: { className: "form-group mb-4" } },
          options: optionData?.district ? [...optionData.district] : [],
          optionProps: { textKey: "name", valueKey: "id" },
          label: {
            text: "District",
            labelProps: { htmlFor: "district", className: "" },
          },
        },
    {
      inputProps: {
        type: "text",
        className: "form-control",
        placeholder: "Pin-Code",
        name: "pincode",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Pin-Code",
        labelProps: { htmlFor: "pincode", className: "" },
      },
    },
    {
      inputProps: {
        type: "text",
        className: "form-control",
        placeholder: "Contact No.",
        name: "mobileno",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Contact No.",
        labelProps: { htmlFor: "mobileno", className: "" },
      },
    },
    {
      inputProps: {
        type: "text",
        className: "form-control",
        placeholder: "Alternate No.",
        name: "phoneno",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Alternate No.",
        labelProps: { htmlFor: "phoneno", className: "" },
      },
    },
    {
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Education-when entered",
        name: "educationbefore",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      options: [
        { text: "Illiterate (never attended school)", value: "illiterate" },
        { text: "Primary up to V", value: "primary" },
        { text: "Middle up to VII", value: "middle" },
        { text: "Secondary up to X", value: "secondary" },
        { text: "Higher Secondary", value: "higher_sec" },
        { text: "Sr. Secondary", value: "senior_sec" },
        { text: "Religious Institution", value: "religious_institution" },
      ],
      label: {
        text: "Education-when entered",
        labelProps: { htmlFor: "educationbefore", className: "" },
      },
    },
    // {
    //   inputProps: {
    //     type: "select",
    //     className: "form-control",
    //     placeholder: "Select",
    //     name: "action_data",
    //   },
    //   className: "col-12 col-sm-6",
    //   parentProps: { input: { className: "form-group mb-4" } },
    //   options: [
    //     { text: "Do Not Found", value: "dnf" },
    //     { text: "Do Not Intervene", value: "dni" },
    //   ],
    //   label: {
    //     text: "Case intervention or not",
    //     labelProps: { htmlFor: "action_data", className: "" },
    //   },
    // },
    {
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select",
        name: "ReferredBy",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      options: [
        { text: "Refer From CWC", value: "refer" },
        { text: "Produced To CWC", value: "produce" },
        { text: "Action Pending", value: "action_pending" },
      ],
      label: {
        text: "CWC Action",
        labelProps: { htmlFor: "ReferredBy", className: "" },
      },
      required: true,
    },
    // {
    //   relatedField: "ReferredBy",
    //   relatedFieldVal: "refer",
    //   inputProps: {
    //     type: "text",
    //     isdatefield: true,
    //     className: "form-control border-right-0",
    //     placeholder: "DD-MM-YY",
    //     name: "refer_date",
    //   },
    //   className: "col-12 col-sm-6",
    //   label: {
    //     text: "Refer Date",
    //     labelProps: { htmlFor: "refer_date", className: "" },
    //   },
    // },
    {
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select",
        name: "short_stay_status",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      options: [
        { text: "Yes", value: "yes" },
        { text: "No", value: "no" },
      ],
      label: {
        text: "Short Stay",
        labelProps: { htmlFor: "short_stay_status", className: "" },
      },
      required: true,
    },
    ...(optionData && optionData?.short_stay_status === "yes"
      ? [
          {
            inputProps: {
              type: "select",
              className: "form-control",
              placeholder: "Select",
              name: "short_stay",
            },
            className: "col-12 col-sm-6",
            parentProps: { input: { className: "form-group mb-4" } },
            options: [
              { text: "Aasra CHB" },
              { text: "Aarushi CHG" },
              { text: "Apna Ghar OS" },
              { text: "Rose Udaan CHG" },
              { text: "DMRC CHB" },
              { text: "Uday OS" },
              { text: "ODRS OS" },
              { text: "Other Homes" },
            ],
            required: true,
            label: {
              text: "SBT Homes",
              labelProps: { htmlFor: "short_stay", className: "" },
            },
          },
        ]
      : []),
    {
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select",
        name: "centerdetailId",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      options: optionData?.centerNames ? [...optionData.centerNames] : [],
      optionProps: { textKey: "name", valueKey: "id" },
      label: {
        text: "Center Name",
        labelProps: { htmlFor: "centerdetailId", className: "" },
      },
      readOnlyField: true,
    },
    {
      inputProps: {
        type: "text",
        className: "form-control",
        placeholder: "Please enter Aadhar No.",
        name: "aadharno",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Aadhar No",
        labelProps: { htmlFor: "aadharno", className: "" },
      },
    },
    // {
    //   inputProps: {
    //     type: "select",
    //     className: "form-control",
    //     placeholder: "Select",
    //     name: "status_of_beneficiaries",
    //   },
    //   className: "col-12 col-sm-6",
    //   parentProps: { input: { className: "form-group mb-4" } },
    //   options: [
    //     { text: "Rescued at station or setelite unit" },
    //     { text: "Moved to Open shelter home" },
    //     { text: "Production before cwc" },
    //     { text: "Restoration to the family from CHD" },
    //     { text: "Restoration to the family from CWC" },
    //     { text: "Restoration to the family from CCI" },
    //     { text: "Moved to long term shelter" },
    //     { text: "Moved to other state CCIs" },
    //     { text: "RCI Shelter" },
    //     { text: "Follow up process" },
    //     { text: "Case closed" },
    //     { text: "Moved to other CCI in the same state" },
    //     { text: "Restoration to the family from open shelter" },
    //   ],
    //   label: {
    //     text: "Status of Beneficiaries",
    //     labelProps: { htmlFor: "status_of_beneficiaries", className: "" },
    //   },
    // },
    // {
    //   inputProps: {
    //     type: "select",
    //     className: "form-control",
    //     placeholder: "Select",
    //     name: "period_street",
    //   },
    //   className: "col-12 col-sm-6",
    //   parentProps: { input: { className: "form-group mb-4" } },
    //   options: [
    //     { text: "N.A" },
    //     { text: "1 Year <" },
    //     { text: "6 Month- 1 Year" },
    //     { text: "3 Month - 6 Month" },
    //     { text: "< 3 Month" },
    //     { text: "One Week" },
    //   ],
    //   label: {
    //     text: "Period of stay on the street",
    //     labelProps: { htmlFor: "period_street", className: "" },
    //   },
    // },
    // {
    //   inputProps: {
    //     type: "select",
    //     className: "form-control",
    //     placeholder: "Select",
    //     name: "occupation_on_street",
    //   },
    //   className: "col-12 col-sm-6",
    //   parentProps: { input: { className: "form-group mb-4" } },
    //   options: [
    //     { text: "N.A", value: "N.A" },
    //     { text: "Anti-Social", value: "anti-social" },
    //     { text: "Sex Worker", value: "sex-worker" },
    //     { text: "Reg Picker", value: "reg-pickers" },
    //     { text: "Organized", value: "organized" },
    //     { text: "Unorganized", value: "unorganized" },
    //   ],
    //   label: {
    //     text: "Occuption On Street",
    //     labelProps: { htmlFor: "occupation_on_street", className: "" },
    //   },
    // },
    {
      inputProps: {
        type: "text",
        className: "form-control",
        placeholder: "CWC Case No.",
        name: "cwccaseno",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "CWC Case No.",
        labelProps: { htmlFor: "cwccaseno", className: "" },
      },
    },
    // {
    //   inputProps: {
    //     type: "select",
    //     className: "form-control",
    //     placeholder: "Select",
    //     name: "contacted_through_referred_found",
    //   },
    //   className: "col-12 col-sm-6",
    //   parentProps: { input: { className: "form-group mb-4" } },
    //   options: [
    //     { text: "Social Workers", value: "Social_Workers" },
    //     { text: "Allied Systems", value: "Allied_Systems" },
    //     {
    //       text: "Cleaning Staff/House Keeping",
    //       value: "Cleaning_Staff_House_Keeping",
    //     },
    //     { text: "Vendor/Hawker/Porter", value: "Vendor_Hawker_Porter" },
    //     { text: "Auto/Taxi Driver", value: "Auto_Taxi_Driver" },
    //   ],
    //   label: {
    //     text: "Contacted throught/Referred found",
    //     labelProps: {
    //       htmlFor: "contacted_through_referred_found",
    //       className: "",
    //     },
    //   },
    // },
    {
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select",
        name: "case_type",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      options: [
        { text: "Orphaned" },
        { text: "Abandoned" },
        { text: "Missing" },
        { text: "Runaway" },
        { text: "Trafficking" },
        { text: "Abused" },
        { text: "CNCP" },
        { text: "Others" },
      ],
      label: {
        text: "Case type",
        labelProps: { htmlFor: "case_type", className: "" },
      },
    },
  ];
};

export const documentsFormFixture = (optionData) => {
  return [
    {
      inputProps: {
        type: "searchableSelect",
        className: "form-control",
        placeholder: "Select Tag",
        name: "tagId",
      },
      className: "col-12 col-sm-6",
      parentClassName: "row",
      parentProps: { input: { className: "form-group mb-4" } },
      optionProps: { textKey: "name", valueKey: "id" },
      options: optionData?.tagNames ? [...optionData.tagNames] : [],
      label: {
        text: "Tag Name",
        labelProps: { htmlFor: "tagId", className: "" },
      },
      row: "new",
    },
    {
      inputProps: {
        type: "text",
        isdatefield: true,
        className: "form-control border-right-0",
        placeholder: "DD-MM-YY",
        name: "doc_date",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "Document's Date",
        labelProps: { htmlFor: "doc_date", className: "" },
      },
    },
    {
      parentProps: {
        input: { className: "form-group mb-4 registration-form" },
      },
      inputProps: {
        type: "file",
        className: "fileChoose",
        name: "filename",
        // accept: "",
        id: "formFileLg",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "Document",
        labelProps: { htmlFor: "filename", className: "" },
      },
    },
    {
      parentProps: {
        input: { className: "form-group mb-4 registration-form" },
      },
      inputProps: {
        type: "file",
        className: "fileChoose",
        name: "profile_audio_name",
        accept: "audio/*",
        id: "formFileLg",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "Audio",
        labelProps: { htmlFor: "profile_audio_name", className: "" },
      },
    },
    {
      inputProps: {
        type: "textarea",
        className: "form-control",
        placeholder: "Write here ...",
        name: "comment",
      },
      className: "col-12",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Receiver's Name and her/his observation & Contact Details",
        labelProps: { htmlFor: "comment", className: "" },
      },
    },
  ];
};

export const additionalInfoFormFixture = (optionData = {}) => {
  let arr = [];
  arr.push({
    inputProps: {
      type: "select",
      className: "form-control",
      name: "reference_type",
    },
    className: "col-12",
    parentClassName: "row",
    parentProps: { input: { className: "form-group mb-4" } },
    options: [
      { text: "Child Helpline", value: "Child Helpline" },
      { text: "Self", value: "Self" },
      { text: "From CWC", value: "From CWC" },
      { text: "From Stakeholder", value: "From Stakeholder" },
      { text: "Out Reach", value: "Out Reach" },
      { text: "Police", value: "bypolice" },
      { text: "Voluntarily", value: "Voluntarily" },
      { text: "Others", value: "byother" },
    ],
    label: {
      text: "Reference By",
      labelProps: { htmlFor: "reference_type", className: "" },
    },
    row: "new",
  });
  if (
    optionData["reference_type"] === "Child Helpline" ||
    optionData["reference_type"] === "Out Reach"
  ) {
    arr.push({
      inputProps: {
        type: "textarea",
        className: "form-control",
        placeholder: "Write here ...",
        name: "otherref",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Reference Details",
        labelProps: { htmlFor: "otherref", className: "" },
      },
    });
  }
  if (optionData["reference_type"] === "bypolice") {
    arr.push(
      {
        inputProps: {
          type: "text",
          className: "form-control",
          placeholder: "Daily Dairy Report No",
          name: "daily_report_no",
        },
        className: "col-12 col-sm-6",
        parentProps: { input: { className: "form-group mb-4" } },
        label: {
          text: "Daily Dairy Report No.",
          labelProps: { htmlFor: "daily_report_no", className: "" },
        },
      },
      {
        inputProps: {
          type: "text",
          className: "form-control",
          placeholder: "Produced By",
          name: "police_produced_by",
        },
        className: "col-12 col-sm-6",
        parentProps: { input: { className: "form-group mb-4" } },
        label: {
          text: "Produced By",
          labelProps: { htmlFor: "police_produced_by", className: "" },
        },
      },
      {
        inputProps: {
          type: "textarea",
          className: "form-control",
          placeholder: "Write here ...",
          name: "police_station",
        },
        className: "col-12 col-sm-6",
        parentProps: { input: { className: "form-group mb-4" } },
        label: {
          text: "Police Station Name & Details",
          labelProps: { htmlFor: "police_station", className: "" },
        },
      }
    );
  }
  if (optionData["reference_type"] === "byother") {
    arr.push({
      inputProps: {
        type: "textarea",
        className: "form-control",
        placeholder: "Write here ...",
        name: "reference_type_other",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Other Detail",
        labelProps: { htmlFor: "reference_type_other", className: "" },
      },
    });
  }
  return [
    ...arr,
    {
      inputProps: {
        type: "textarea",
        className: "form-control",
        placeholder: "Write here ...",
        name: "breif",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Child’s Brief",
        labelProps: { htmlFor: "breif", className: "" },
      },
    },
    // {
    //   inputProps: {
    //     type: "select",
    //     className: "form-control",
    //     placeholder: "Select",
    //     name: "circumstance_of_rescue",
    //   },
    //   className: "col-12",
    //   parentProps: { input: { className: "form-group mb-4" } },
    //   options: [
    //     { text: "Orphaned" },
    //     { text: "Abandoned" },
    //     { text: "Missing/separated" },
    //     { text: "Run away" },
    //     { text: "Possibility Trafficked" },
    //     { text: "Abused" },
    //     { text: "Other" },
    //   ],
    //   label: {
    //     text: "Circumstance of Rescue",
    //     labelProps: { htmlFor: "circumstance_of_rescue", className: "" },
    //   },
    // },
    {
      inputProps: {
        type: "textarea",
        className: "form-control",
        placeholder: "Write here ...",
        name: "comments",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Comment",
        labelProps: { htmlFor: "comments", className: "" },
      },
    },
    {
      inputProps: {
        type: "textarea",
        className: "form-control",
        placeholder: "Write here ...",
        name: "jamatalashi",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Jama-Talashi",
        labelProps: { htmlFor: "jamatalashi", className: "" },
      },
    },
  ];
};

export const overviewFormInputFixture = {
  name: "",
  gender: "",
  dateofarrival: "",
  image: "",
};

export const basicInfoFormInputFixture = (data) => ({
  family_touch: "",
  f_name: "",
  m_name: "",
  occupation: "",
  income: "",
  no_sibling: "",
  age: "",
  dob: "",
  caste: "",
  address: "",
  countryId: "101", // India
  stateId: "",
  cityId: "",
  district: "",
  pincode: "",
  mobileno: "",
  phoneno: "",
  educationbefore: "",
  action_data: "",
  ReferredBy: "",
  // refer_date: "",
  centerdetailId: data?.centerId || "",
  aadharno: "",
  status_of_beneficiaries: "",
  period_street: "",
  occupation_on_street: "",
  cwccaseno: "",
  contacted_through_referred_found: "",
  case_type: "",
  short_stay_status: "",
  short_stay: "",
});

export const documentsInputFixture = {
  tagId: "",
  doc_date: "",
  comment: "",
  status: 1,
  filename: "",
  profile_audio_name: "",
};

export const additionalInfoInputFixture = {
  reference_type: "",
  otherref: "",
  breif: "",
  circumstance_of_rescue: "",
  comments: "",
  jamatalashi: "",
  daily_report_no: "",
  police_produced_by: "",
  police_station: "",
  reference_type_other: "",
};

export const ChildRegisterFormsFixture = (optionData, action) => {
  return [
    overviewFormFixture(action),
    basicInfoFormFixture(optionData),
    documentsFormFixture(optionData),
    additionalInfoFormFixture(optionData),
  ];
};

export const childRegisterInputFixture = (data = {}) => ({
  ...overviewFormInputFixture,
  ...basicInfoFormInputFixture(data),
  ...additionalInfoInputFixture,
});

export const registerValidationField = (activeTab = 0) => {
  let fields = {
    0: [
      { key: "name", label: "Name" },
      { key: "dateofarrival", label: "Date of Arrival" },
      { key: "gender", label: "Gender" },
    ],
    1: [
      { key: "ReferredBy", label: "CWC Action" },
      {
        key: "cwccaseno",
        label: "CWC Case No.",
        relatedkey: "ReferredBy",
        relatedVal: "refer",
      },
      { key: "short_stay_status", label: "Short Stay" },
      {
        key: "short_stay",
        label: "SBT Homes",
        relatedkey: "short_stay_status",
        relatedVal: "yes",
      },
      { key: "aadharno", optional: true, label: "Aadhar No" },
      { key: "mobileno", optional: true, label: "Contact No." },
      { key: "phoneno", optional: true, label: "Alternate No." },
    ],
    2: [],
    3: [],
  };
  return fields[activeTab];
};

export const registerNumberOnlyInputs = ["no_sibling"];

export const profileTabFixture = [
  "Overview",
  "Basic Information",
  "Images & Documents",
  "Additional Information",
];

export const activityTableHeaderFixture = [
  { text: "S No.", key: "serial_num" },
  { text: "Center Name", objkey: "center_data", key: "name" },
  { text: "Unique Center Id", key: "unique_centerId" },
  { text: "Activity Log", key: "history_status", formatVal: true },
  { text: "Action Performed By", objkey: "user_data_history", key: "name" },
  { text: "Activity Date", key: "transfer_date" },
  { text: "Created Date Time", key: "created_at", formatDate: true },
  { text: "Modified Date-Time", key: "updated_at", formatDate: true },
  {
    text: "Action",
    action: true,
    key: ["delete"],
    actionFor: "child_registration",
  },
];

export const documentsTableHeaderFixture = [
  { text: "S No.", key: "serial_num" },
  { text: "Tag Name", objkey: "tag", key: "name" },
  {
    text: "File Name",
    action: true,
    key: ["viewFile", "downloadFile"],
    fileKeyname: "filename",
  },
  {
    text: "Audio File Name",
    action: true,
    key: ["viewFile", "downloadFile"],
    audioFile: true,
    fileKeyname: "profile_audio_name",
  },
  { text: "Uploaded By", objkey: "user_data_history", key: "name" },
  { text: "Comments", key: "comment" },
  { text: "Created Date-Time", key: "created_at", formatDate: true },
  {
    text: "Action",
    action: true,
    key: ["delete"],
    actionFor: "child_registration",
  },
];

export const restorationTableHeaderFixture = [
  { text: "S No.", key: "serial_num" },
  { text: "Center Name", objkey: "center_data", key: "name" },
  { text: "Restore To", key: "restore_to" },
  { text: "Restore Date", key: "transfer_date" },
  { text: "Restore Address", key: "restore_to_address" },
  { text: "Restore Comments", key: "comment" },
  { text: "Created By", objkey: "user_data_history", key: "name" },
  { text: "Created Date-Time", key: "created_at", formatDate: true },
  { text: "Modified Date-Time", key: "updated_at", formatDate: true },
  {
    text: "Action",
    action: true,
    key: ["delete"],
    actionFor: "child_registration",
  },
];

export const followUpTableHeaderFixture = [
  { text: "S No.", key: "serial_num" },
  { text: "Center Name", objkey: "center_data", key: "name" },
  { text: "Follow Up Date", key: "transfer_date" },
  { text: "Follow Up Comments", key: "comment" },
  { text: "Documents", action: true, key: ["viewFile"] },
  { text: "Created By", objkey: "user_data_history", key: "name" },
  { text: "Created Date-Time", key: "created_at", formatDate: true },
];

export const statusTableHeaderFixture = [
  { text: "S No.", key: "serial_num" },
  { text: "Center Name", objkey: "center_data", key: "name" },
  // { text: "Transfer To Other Center", key: "" },
  { text: "Date of Action", key: "history_date" },
  { text: "Comments", key: "comment" },
  // { text: "Action Performed By", key: "" },
  { text: "Status", key: "history_status", formatVal: true },
  { text: "Created Date-Time", key: "created_at" },
  // { text: "Modified Date-Time", key: "" },
  {
    text: "Action",
    action: true,
    key: ["delete"],
    actionFor: "child_registration",
  },
];

export const medicalTableHeaderFixture = [
  { text: "S No.", key: "serial_num" },
  { text: "Date of CheckUp", key: "transfer_date" },
  { text: "Center Name", objkey: "center_data", key: "name" },
  { text: "Comments", key: "comment" },
  { text: "Medical", key: "medical_issues", formatVal: true },
  { text: "Document", action: true, key: ["viewFile"] },
  { text: "Created Date-Time", key: "created_at", formatDate: true },
  { text: "Uploaded By", objkey: "user_data_history", key: "name" },
  {
    text: "Action",
    action: true,
    key: ["edit", "delete"],
    actionFor: "child_registration",
  },
];

export const educationTableHeaderFixture = [
  { text: "S No.", key: "serial_num" },
  { text: "Date of Entry", key: "transfer_date" },
  { text: "Center Name", objkey: "center_data", key: "name" },
  { text: "Comments", key: "comment" },
  { text: "Educational Status", key: "education_type", formatVal: true },
  { text: "Documents", action: true, key: ["viewFile"] },
  { text: "Formal Schooling Level", key: "education_level" },
  { text: "Created Date-Time", key: "created_at", formatDate: true },
  { text: "Uploaded By", objkey: "user_data_history", key: "name" },
  {
    text: "Action",
    action: true,
    key: ["edit", "delete"],
    actionFor: "child_registration",
  },
];

export const mhpTableHeaderFixture = [
  { text: "S No.", key: "serial_num" },
  { text: "Date of Entry", key: "transfer_date" },
  { text: "Center Name", objkey: "center_data", key: "name" },
  { text: "Comments", key: "comment" },
  { text: "Other Info", key: "other", formatVal: true },
  { text: "Document", action: true, key: ["viewFile"] },
  { text: "Created Date-Time", key: "created_at", formatDate: true },
  { text: "Uploaded By", objkey: "user_data_history", key: "name" },
  {
    text: "Action",
    action: true,
    key: ["edit", "delete"],
    actionFor: "child_registration",
  },
];

export const childDetailTableHeaderFixture = (fixtureFor) => {
  return fixtureFor === "Activity Log"
    ? activityTableHeaderFixture
    : fixtureFor === "Documents"
    ? documentsTableHeaderFixture
    : fixtureFor === "Restoration Follow-Up" || fixtureFor === "Restore"
    ? restorationTableHeaderFixture
    : fixtureFor === "Centers"
    ? statusTableHeaderFixture
    : fixtureFor === "Medical"
    ? medicalTableHeaderFixture
    : fixtureFor === "Education"
    ? educationTableHeaderFixture
    : fixtureFor === "MHP"
    ? mhpTableHeaderFixture
    : [];
};

export const mhpFormFixture = (optionData) => {
  return [
    {
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select Center",
        name: "centerId",
      },
      className: "col-12 col-sm-6",
      parentClassName: "row",
      parentProps: { input: { className: "form-group mb-4" } },
      options: optionData?.centerNames ? [...optionData.centerNames] : [],
      optionProps: { textKey: "name", valueKey: "id" },
      label: {
        text: "Center Name",
        labelProps: { htmlFor: "centerId", className: "" },
      },
      row: "new",
      required: true,
    },
    {
      inputProps: {
        type: "text",
        isdatefield: true,
        className: "form-control border-right-0",
        placeholder: "DD-MM-YY",
        name: "transfer_date",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "Date",
        labelProps: { htmlFor: "transfer_date", className: "" },
      },
      required: true,
    },
    {
      inputProps: {
        type: "select",
        className: "form-control",
        name: "mhp",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      options: mhpOptionList,
      label: {
        text: "MHP",
        labelProps: { htmlFor: "mhp", className: "" },
      },
      required: true,
    },
    {
      inputProps: {
        type: "select",
        className: "form-control",
        name: "other",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      options: otherOptionList,
      label: {
        text: "Others",
        labelProps: { htmlFor: "other", className: "" },
      },
      // required: true,
    },
    {
      inputProps: {
        type: "textarea",
        className: "form-control",
        placeholder: "Write here ...",
        name: "comment",
      },
      className: "col-12",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Comments",
        labelProps: { htmlFor: "comment", className: "" },
      },
      required: true,
    },
  ];
};

export const mhpFormInputFixture = (data) => ({
  centerId: data?.centerId || "",
  transfer_date: "",
  mhp: "",
  other: "",
  comment: "",
});

export const educationFormFixture = (optionData) => {
  return [
    {
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select Center",
        name: "centerId",
      },
      className: "col-12 col-sm-6",
      parentClassName: "row",
      parentProps: { input: { className: "form-group mb-4" } },
      options: optionData?.centerNames ? [...optionData.centerNames] : [],
      optionProps: { textKey: "name", valueKey: "id" },
      label: {
        text: "Center Name",
        labelProps: { htmlFor: "centerId", className: "" },
      },
      row: "new",
      required: true,
    },
    {
      inputProps: {
        type: "text",
        isdatefield: true,
        className: "form-control border-right-0",
        placeholder: "DD-MM-YY",
        name: "transfer_date",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "Educational Date",
        labelProps: { htmlFor: "transfer_date", className: "" },
      },
      required: true,
    },
    {
      inputProps: {
        type: "select",
        className: "form-control",
        name: "education_type",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      options: educationOptionList,
      label: {
        text: "Education",
        labelProps: { htmlFor: "education_type", className: "" },
      },
      required: true,
    },
    {
      inputProps: {
        type: "select",
        className: "form-control",
        name: "education_level",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      options: educationLevelOptionList,
      label: {
        text: "Level",
        labelProps: { htmlFor: "education_level", className: "" },
      },
      // required: true,
    },
    {
      parentProps: {
        input: { className: "form-group mb-4 registration-form" },
      },
      inputProps: {
        type: "file",
        className: "fileChoose",
        name: "documents",
        id: "formFileLg",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "Documents",
        labelProps: { htmlFor: "documents", className: "" },
      },
      required: true,
    },
    {
      inputProps: {
        type: "textarea",
        className: "form-control",
        placeholder: "Write here ...",
        name: "comment",
      },
      className: "col-12",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Comments",
        labelProps: { htmlFor: "comment", className: "" },
      },
      required: true,
    },
  ];
};

export const educationFormInputFixture = (data) => ({
  centerId: data?.centerId || "",
  education_type: "",
  comment: "",
  transfer_date: "",
  documents: "",
  education_level: "",
});

export const medicalFormFixture = (optionData) => {
  return [
    {
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select Center",
        name: "centerId",
      },
      className: "col-12 col-sm-6",
      parentClassName: "row",
      parentProps: { input: { className: "form-group mb-4" } },
      options: optionData?.centerNames ? [...optionData.centerNames] : [],
      optionProps: { textKey: "name", valueKey: "id" },
      label: {
        text: "Center Name",
        labelProps: { htmlFor: "centerId", className: "" },
      },
      row: "new",
      required: true,
    },
    {
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select Medical Issue",
        name: "medical_issues",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      options: medicalOptionList,
      label: {
        text: "Medical Issue",
        labelProps: { htmlFor: "medical_issues", className: "" },
      },
      required: true,
    },
    {
      inputProps: {
        type: "text",
        isdatefield: true,
        className: "form-control border-right-0",
        placeholder: "DD-MM-YY",
        name: "transfer_date",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "Medical Test Date",
        labelProps: { htmlFor: "transfer_date", className: "" },
      },
      required: true,
    },
    {
      parentProps: {
        input: { className: "form-group mb-4 registration-form" },
      },
      inputProps: {
        type: "file",
        className: "fileChoose",
        name: "documents",
        id: "formFileLg",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "Documents",
        labelProps: { htmlFor: "documents", className: "" },
      },
      required: true,
    },
    {
      inputProps: {
        type: "textarea",
        className: "form-control",
        placeholder: "Write here ...",
        name: "comment",
      },
      className: "col-12",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Comments",
        labelProps: { htmlFor: "comment", className: "" },
      },
      required: true,
    },
  ];
};

export const medicalFormInputFixture = (data) => ({
  centerId: data?.centerId || "",
  medical_issues: "",
  transfer_date: "",
  documents: "",
  comment: "",
});

export const restorationFormFixture = (
  optionData,
  fixtureFor = "restoration_follow_up"
) => {
  return [
    {
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select",
        name: "centerId",
      },
      className: "col-12 col-sm-6",
      parentClassName: "row",
      parentProps: { input: { className: "form-group mb-4" } },
      options: optionData?.centerNames ? [...optionData.centerNames] : [],
      optionProps: { textKey: "name", valueKey: "id" },
      label: {
        text: "Current Center",
        labelProps: { htmlFor: "centerId", className: "" },
      },
      row: "new",
      required: true,
      readOnlyField: true,
    },
    {
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select",
        name: "restoration_method",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      options: [
        { text: "By Telephone/CWC", value: "bytelephone" },
        { text: "By Visit", value: "byvisit" },
      ],
      label: {
        text: "Restoration Method",
        labelProps: { htmlFor: "restoration_method", className: "" },
      },
      required: true,
    },
    {
      inputProps: {
        type: "text",
        className: "form-control",
        name: "restore_to",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Restored To",
        labelProps: { htmlFor: "restore_to", className: "" },
      },
      required: true,
    },
    {
      inputProps: {
        type: "text",
        isdatefield: true,
        className: "form-control border-right-0",
        placeholder: "DD-MM-YY",
        name: "transfer_date",
      },
      className: "col-12 col-sm-6",
      label: {
        text:
          fixtureFor === "restoration"
            ? "Restoration date"
            : "Restoration follow up date ",
        labelProps: { htmlFor: "transfer_date", className: "" },
      },
      required: true,
    },
    {
      inputProps: {
        type: "textarea",
        className: "form-control",
        placeholder: "Write here ...",
        name: "restore_to_address",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Restoration Address",
        labelProps: { htmlFor: "restore_to_address", className: "" },
      },
      required: true,
    },
    {
      inputProps: {
        type: "textarea",
        className: "form-control",
        placeholder: "Write here ...",
        name: "comment",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Restoration Comments",
        labelProps: { htmlFor: "comment", className: "" },
      },
      required: true,
    },
  ];
};

export const restorationFormInputFixture = (currentCenterId) => ({
  centerId: currentCenterId || "",
  restoration_method: "",
  restore_to: "",
  transfer_date: "",
  restore_to_address: "",
  comment: "",
});

export const restoreDocsFormFixture = (optionData) => {
  return [
    {
      inputProps: {
        type: "searchableSelect",
        className: "form-control",
        placeholder: "Select Tag",
        name: "tagId",
      },
      className: "col-12 col-sm-6",
      parentClassName: "row",
      parentProps: { input: { className: "form-group mb-4" } },
      optionProps: { textKey: "name", valueKey: "id" },
      options: optionData?.tagNames ? [...optionData.tagNames] : [],
      label: {
        text: "Tag Name",
        labelProps: { htmlFor: "tagId", className: "" },
      },
      row: "new",
      required: true,
    },
    {
      inputProps: {
        type: "text",
        isdatefield: true,
        className: "form-control border-right-0",
        placeholder: "DD-MM-YY",
        name: "doc_date",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "Document's Date",
        labelProps: { htmlFor: "doc_date", className: "" },
      },
      required: true,
    },
    {
      parentProps: {
        input: { className: "form-group mb-4 registration-form" },
      },
      inputProps: {
        type: "file",
        className: "fileChoose",
        name: "filename",
        id: "formFileLg",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "Document",
        labelProps: { htmlFor: "filename", className: "" },
      },
      required: true,
    },
    {
      inputProps: {
        type: "textarea",
        className: "form-control",
        placeholder: "Write here ...",
        name: "comment",
      },
      className: "col-12",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Comment",
        labelProps: { htmlFor: "comment", className: "" },
      },
      required: true,
    },
  ];
};

export const restoreDocsInputFormFixture = {
  tagId: "",
  doc_date: "",
  filename: "",
  status: 1,
  comment: "",
};

export const transferDatelabels = (labelFor) => {
  return labelFor === "Restoration Follow-Up"
    ? "Restoration follow up date"
    : labelFor === "Restore"
    ? "Restoration date"
    : labelFor === "Medical"
    ? "Medical Test Date"
    : labelFor === "Education"
    ? "Educational Date"
    : labelFor === "MHP"
    ? "Date "
    : labelFor === "Escort/Exit"
    ? "Date Of Escort/Exit"
    : "";
};

export const childProfileActionNavFixture = [
  { id: 1, text: "Transfer" },
  { id: 2, text: "Escort/Exit" },
  { id: 3, text: "Restore" },
];

export const transferFormFixture = (optionData) => {
  return [
    {
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select",
        name: "current_centerId",
        disabled: true,
      },
      className: "col-12 col-sm-6",
      parentClassName: "row",
      parentProps: { input: { className: "form-group mb-4" } },
      options: optionData?.centerNames ? [...optionData.centerNames] : [],
      optionProps: { textKey: "name", valueKey: "id" },
      label: {
        text: "Current Center",
        labelProps: { htmlFor: "current_centerId", className: "" },
      },
      row: "new",
      required: true,
    },
    {
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select",
        name: "centerId",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      options: optionData?.centerNames ? [...optionData.centerNames] : [],
      optionProps: { textKey: "name", valueKey: "id" },
      label: {
        text: "Transfer To Center",
        labelProps: { htmlFor: "centerId", className: "" },
      },
      required: true,
    },
    {
      inputProps: {
        type: "text",
        isdatefield: true,
        className: "form-control border-right-0",
        placeholder: "DD-MM-YY",
        name: "transfer_date",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "Transfer Date",
        labelProps: { htmlFor: "transfer_date", className: "" },
      },
      required: true,
    },

    {
      inputProps: {
        type: "textarea",
        className: "form-control",
        placeholder: "Write here ...",
        name: "comment",
      },
      className: "col-12",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Transfer Comments",
        labelProps: { htmlFor: "comment", className: "" },
      },
      required: true,
    },
  ];
};

export const transferFormInputFixture = (currentCenterId) => {
  return {
    current_centerId: currentCenterId ? currentCenterId : "",
    centerId: "",
    transfer_date: "",
    comment: "",
  };
};

export const escortFormFixture = (optionData) => {
  return [
    {
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select",
        name: "centerId",
        disabled: true,
      },
      className: "col-12 col-sm-6",
      parentClassName: "row",
      parentProps: { input: { className: "form-group mb-4" } },
      options: optionData?.centerNames ? [...optionData.centerNames] : [],
      optionProps: { textKey: "name", valueKey: "id" },
      label: {
        text: "Current Center",
        labelProps: { htmlFor: "centerId", className: "" },
      },
      row: "new",
      required: true,
    },
    {
      inputProps: {
        type: "select",
        className: "form-control",
        name: "exit_type",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      options: [{ text: "escort" }, { text: "exit" }],
      label: {
        text: "Exit Type",
        labelProps: { htmlFor: "exit_type", className: "" },
      },
      hideSelectOption: true,
      required: true,
    },
    {
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select",
        name: "escort_exit_method",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      options:
        optionData?.exit_type === "exit"
          ? [
              { text: "Run-Away", value: "run_away" },
              { text: "Short Stay", value: "short_stay" },
              { text: "Rehabilitation", value: "rehabilitation" },
              { text: "After Care", value: "after_care" },
              { text: "Dropout", value: "dropout" },
              { text: "Other CCI", value: "other_cci" },
              { text: "SBT Home", value: "other_ngo" },
              { text: "IO-Police", value: "io_police" },
              {
                text: "Transfer to Home State CWC",
                value: "transfer_to_home_state_CWC",
              },
            ]
          : [
              { text: "By Police", value: "by_police" },
              { text: "By SBT Staff", value: "by_sbt_staff" },
              { text: "By Third Party", value: "by_third_party" },
            ],
      optionProps: { textKey: "name", valueKey: "id" },
      label: {
        text: "Exit Method",
        labelProps: { htmlFor: "escort_exit_method", className: "" },
      },
      required: true,
    },
    {
      inputProps: {
        type: "text",
        className: "form-control",
        name: "escort_exit_to",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Escort/Exit To",
        labelProps: { htmlFor: "escort_exit_to", className: "" },
      },
      required: true,
    },

    {
      inputProps: {
        type: "textarea",
        className: "form-control",
        placeholder: "Write here ...",
        name: "escort_exit_address",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Escort/Exit Address",
        labelProps: { htmlFor: "escort_exit_address", className: "" },
      },
      required: true,
    },
    {
      inputProps: {
        type: "textarea",
        className: "form-control",
        placeholder: "Write here ...",
        name: "comment",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Escort/Exit Comments",
        labelProps: { htmlFor: "comment", className: "" },
      },
      required: true,
    },
    {
      inputProps: {
        type: "text",
        isdatefield: true,
        className: "form-control border-right-0",
        placeholder: "DD-MM-YY",
        name: "transfer_date",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "Date Of Escort/Exit",
        labelProps: { htmlFor: "transfer_date", className: "" },
      },
      required: true,
    },
  ];
};

export const escortFormInputFixture = (currentCenterId) => {
  return {
    centerId: currentCenterId ? currentCenterId : "",
    exit_type: "escort",
    escort_exit_method: "",
    escort_exit_to: "",
    escort_exit_address: "",
    transfer_date: "",
    comment: "",
  };
};

const educationOptionList = [
  { text: "Formal School", value: "formal_school" },
  { text: "Non Formal Education", value: "non_formal_education" },
  {
    text: "National Institute of Open Schooling",
    value: "national_institute_of_open_schooling",
  },
  { text: "Higher Education", value: "higher_education" },
  { text: "Vocational Training", value: "vocational_training" },
  { text: "Job Placement", value: "job_placement" },
  { text: "Religious Institution", value: "religious_institution" },
];

const educationLevelOptionList = [
  { text: "I", value: "I" },
  { text: "II", value: "II" },
  {
    text: "III",
    value: "III",
  },
  { text: "IV", value: "IV" },
  { text: "V", value: "V" },
  { text: "VI", value: "VI" },
  { text: "VII", value: "VII" },
  { text: "VIII", value: "VIII" },
  { text: "IX", value: "IX" },
  { text: "X", value: "X" },
  { text: "XI", value: "XI" },
  { text: "XII", value: "XII" },
  { text: "NFE", value: "NFE" },
  { text: "NIOS", value: "NIOS" },
  { text: "Higher Education", value: "higher_education" },
];

const mhpOptionList = [
  {
    text: "Referral Psychological Disorders",
    value: "referral_psychological_disorders",
  },
  { text: "Conduct Disorder", value: "conduct_disorder" },
  { text: "Depression", value: "depression" },
  { text: "Anxiety Disorder", value: "anxiety_disorder" },
  { text: "Post Traumatic Disorder", value: "post_traumatic_disorder" },
  { text: "Learning Disability", value: "learning_disability" },
  { text: "ADHD", value: "adhd" },
  { text: "Intellectual Disability", value: "intellectual_disability" },
  { text: "Autism Spectrum Disorder", value: "autism_spectrum_disorder" },
  { text: "Psychotic Disorder", value: "psychotic_disorder" },
];
const otherOptionList = [
  { text: "LSE Sessions", value: "lse_sessions" },
  { text: "Group Sessions", value: "group_sessions" },
  { text: "Rescued", value: "rescued" },
  { text: "Annul Tour", value: "annul_tour" },
  { text: "Psycho Social Session", value: "psycho_social_session" },
  { text: "Referral Session", value: "referral_session" },
  { text: "Career Counseling", value: "career_counseling" },
  { text: "Annual play", value: "annual_play" },
  { text: "Local Tour", value: "local_tour" },
];
const medicalOptionList = [
  { text: "Referred to hospital", value: "reffer_hosptial" },
  { text: "Hospitalized", value: "hospitalized" },
  { text: "Operated", value: "operated" },
  { text: "Tetanus injection", value: "tetanus_injection" },
  { text: "MMR vaccination", value: "MMR_vaccination" },
  { text: "Typhoid vaccination", value: "typhoid_vaccination" },
  { text: "Hap B injection", value: "hap_b_injection" },
  { text: "Medical Investigation", value: "medical_investigation" },
  { text: "Long Term Treatment", value: "long_term_treatment" },
  { text: "HIV Test", value: "HIV_test" },
  { text: "Eye Check Up", value: "eye_check_up" },
  { text: "Dental Check Up", value: "dental_check_up" },
  { text: "Referred MHP", value: "referred_MHP" },
  {
    text: "Referred To Drug Addiction",
    value: "referred_to_drug_addiction",
  },
  { text: "In-house Medical Checkup", value: "in_house_medical_checkup" },
  { text: "Medical Checkup", value: "medical_checkup" },
  { text: "Covid vaccination", value: "covid_vaccination" },
  { text: "Referred to MHP (Special cases)", value: "referred_to_MHP" },
  {
    text: "Referred to drug De-addiction",
    value: "referred_to_drug_de_addiction",
  },
];

// bulk upload
export const bulkUploadFormFixture = (fixtureFor, optionData) => {
  let arr = [
    // {
    //   inputProps: {
    //     type: "select",
    //     className: "form-control",
    //     placeholder: "Select",
    //     name: "centerId",
    //   },
    //   parentClassName: "row mb-3",
    //   className: "col-12 col-sm-6",
    //   options: optionData?.centerNames ? [...optionData.centerNames] : [],
    //   optionProps: { textKey: "name", valueKey: "id" },
    //   label: {
    //     text: "Center Name",
    //     labelProps: { htmlFor: "centerId", className: "" },
    //   },
    //   required: true,
    // },
    {
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select",
        name: "update_type",
      },
      className: "col-12 col-sm-6",
      parentClassName: "row mb-3",
      options: [
        { text: "Education", value: "education" },
        { text: "Medical", value: "medical" },
        { text: "MHP", value: "mhp" },
        { text: "Follow-Ups", value: "follow-ups" },
      ],
      label: {
        text: "Update Type",
        labelProps: { htmlFor: "update_type", className: "" },
      },
      hideSelectOption: true,
      row: "new",
      required: true,
    },
  ];
  if (fixtureFor === "follow-ups") {
    arr.push(
      {
        inputProps: {
          type: "select",
          className: "form-control",
          placeholder: "Select",
          name: "followupmethod",
        },
        className: "col-12 col-sm-6",
        // parentClassName: "row mb-3",
        parentProps: { input: { className: "form-group mb-4" } },
        options: [
          { text: "By Telephone/CWC", value: "bytelephone" },
          { text: "By Visit", value: "byvisit" },
          { text: "By E-mail", value: "byemail" },
        ],
        label: {
          text: "Follow-Up Method",
          labelProps: { htmlFor: "followupmethod", className: "" },
        },
        // row: "new",
        required: true,
      }
      // {
      //   parentProps: {
      //     input: { className: "form-group mb-4 registration-form" },
      //   },
      //   inputProps: {
      //     type: "file",
      //     className: "fileChoose",
      //     name: "filename",
      //     id: "formFileLg",
      //   },
      //   className: "col-12 col-sm-6",
      //   label: {
      //     text: "Follow-Up Document",
      //     labelProps: { htmlFor: "filename", className: "" },
      //   },
      //   required: false,
      // }
    );
  }
  if (fixtureFor === "education") {
    arr.push(
      {
        inputProps: {
          type: "select",
          className: "form-control",
          placeholder: "Select",
          name: "education_type",
        },
        className: "col-12 col-sm-6",
        // options: optionData?.education_list ? [...optionData.education_list] : [],
        options: educationOptionList,
        label: {
          text: "Education",
          labelProps: { htmlFor: "education_type", className: "" },
        },
        required: true,
      },
      {
        inputProps: {
          type: "select",
          className: "form-control",
          name: "education_level",
        },
        className: "col-12 col-sm-6",
        parentProps: { input: { className: "form-group mb-4" } },
        options: educationLevelOptionList,
        label: {
          text: "Level",
          labelProps: { htmlFor: "education_level", className: "" },
        },
        // required: true,
      }
    );
  } else if (fixtureFor === "medical") {
    arr.push({
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select",
        name: "medical_issues",
      },
      className: "col-12 col-sm-6",
      // options: optionData?.medical_list ? [...optionData.medical_list] : [],
      options: medicalOptionList,
      label: {
        text: "Medical Issues",
        labelProps: { htmlFor: "medical_issues", className: "" },
      },
      required: true,
    });
  } else if (fixtureFor === "mhp") {
    arr.push(
      {
        inputProps: {
          type: "select",
          className: "form-control",
          placeholder: "Select",
          name: "mhp",
        },
        className: "col-12 col-sm-6",
        // options: optionData?.mhp_list ? [...optionData.mhp_list] : [],
        options: mhpOptionList,
        label: {
          text: "Mhp",
          labelProps: { htmlFor: "mhp", className: "" },
        },
        required: true,
      },
      {
        inputProps: {
          type: "select",
          className: "form-control",
          placeholder: "Select",
          name: "other",
        },
        className: "col-12 col-sm-6",
        // options: optionData?.other_list ? [...optionData.other_list] : [],
        options: otherOptionList,
        label: {
          text: "Other",
          labelProps: { htmlFor: "other", className: "" },
        },
        // required: true,
      }
    );
  }

  return [
    ...arr,
    {
      inputProps: {
        type: "text",
        isdatefield: true,
        className: "form-control border-right-0",
        placeholder: "DD-MM-YY",
        name: "date",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "Date",
        labelProps: { htmlFor: "date", className: "" },
      },
      required: true,
    },
    {
      inputProps: {
        type: "textarea",
        className: "form-control",
        placeholder: "Write here ...",
        name: "comment",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Comment",
        labelProps: { htmlFor: "comment", className: "" },
      },
      required: true,
    },
  ];
};

export const bulkUploadInputFixture = {
  date: "",
  comment: "",
  update_type: "education",
  education_type: "",
  education_level: "",
  medical_issues: "",
  mhp: "",
  other: "",
};

export const validateField = (upload_type) => {
  let fields = ["date", "comment"];
  if (upload_type === "education") fields.push("education_type");
  else if (upload_type === "medical") fields.push("medical_issues");
  else if (upload_type === "mhp") fields.push("mhp");
  else if (upload_type === "follow-ups") fields.push("followupmethod");

  return fields;
};

// add edu,med,mhp

export const beneficiaryFormFixture = (fixtureFor, optionData) => {
  let arr = [];
  // if (fixtureFor) {
  //   arr.push({
  //     inputProps: {
  //       type: "select",
  //       className: "form-control",
  //       name:
  //         fixtureFor === "Education"
  //           ? "education"
  //           : fixtureFor === "Medical"
  //           ? "medical"
  //           : fixtureFor === "MHP"
  //           ? "is_mhp"
  //           : "register_type",
  //     },
  //     className: "col-12 col-sm-6",
  //     parentClassName: "row",
  //     hideSelectOption: true,
  //     options: [
  //       { text: "Registered", value: 1 },
  //       { text: "Non Registered", value: 0 },
  //     ],
  //     label: {
  //       text: "Register Type",
  //     },
  //     required: true,
  //     row: "new",
  //   });
  // }
  if (fixtureFor === "Education") {
    arr.push({
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select",
        name: "education_type",
      },
      className: "col-12 col-sm-6",
      // options: optionData?.education_list ? [...optionData.education_list] : [],
      options: educationOptionList,
      label: {
        text: "Education",
        labelProps: { htmlFor: "education_type", className: "" },
      },
      required: true,
      row: "new",
      parentClassName: "row",
    });
  } else if (fixtureFor === "Medical") {
    arr.push({
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select",
        name: "medical_issues",
      },
      className: "col-12 col-sm-6",
      // options: optionData?.medical_list ? [...optionData.medical_list] : [],
      options: medicalOptionList,
      label: {
        text: "Medical Issues",
        labelProps: { htmlFor: "medical_issues", className: "" },
      },
      required: true,
      row: "new",
      parentClassName: "row",
    });
  } else if (fixtureFor === "MHP") {
    arr.push(
      {
        inputProps: {
          type: "select",
          className: "form-control",
          placeholder: "Select",
          name: "mhp",
        },
        className: "col-12 col-sm-6",
        // options: optionData?.mhp_list ? [...optionData.mhp_list] : [],
        options: mhpOptionList,
        label: {
          text: "MHP",
          labelProps: { htmlFor: "mhp", className: "" },
        },
        required: true,
        row: "new",
        parentClassName: "row",
      },
      {
        inputProps: {
          type: "select",
          className: "form-control",
          placeholder: "Select",
          name: "other",
        },
        className: "col-12 col-sm-6",
        // options: optionData?.other_list ? [...optionData.other_list] : [],
        options: otherOptionList,
        label: {
          text: "Other",
          labelProps: { htmlFor: "other", className: "" },
        },
        required: true,
      }
    );
  }

  return [
    ...arr,
    {
      inputProps: {
        type: "text",
        isdatefield: true,
        className: "form-control border-right-0",
        placeholder: "DD-MM-YY",
        name: "transfer_date",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "Transfer Date",
        labelProps: { htmlFor: "transfer_date", className: "" },
      },
      required: true,
    },
    {
      parentProps: {
        input: { className: "form-group mb-4 registration-form" },
      },
      inputProps: {
        type: "file",
        className: "fileChoose",
        name: "documents",
        id: "formFileLg",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "Document",
        labelProps: { htmlFor: "documents", className: "" },
      },
      required: true,
    },
    {
      inputProps: {
        type: "textarea",
        className: "form-control",
        placeholder: "Write here ...",
        name: "comment",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Comment",
        labelProps: { htmlFor: "comment", className: "" },
      },
      required: true,
    },
  ];
};

export const beneficiaryInputFixture = (fixtureFor) => ({
  // register_type: 1,
  transfer_date: "",
  comment: "",
  documents: "",
  ...(fixtureFor === "Education"
    ? {
        // education: 1,
        education_type: "",
      }
    : fixtureFor === "MHP"
    ? {
        // is_mhp: 1,
        mhp: "",
        other: "",
      }
    : fixtureFor === "Medical"
    ? {
        // medical: 1,
        medical_issues: "",
      }
    : {}),
});

export const changeCenterFormFixture = (optionData) => {
  return [
    {
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select",
        name: "centerId",
      },
      parentClassName: "row mb-3",
      className: "col-12 col-sm-6",
      options: optionData?.centerNames ? [...optionData.centerNames] : [],
      optionProps: { textKey: "name", valueKey: "id" },
      label: {
        text: "Center Name",
        labelProps: { htmlFor: "centerId", className: "" },
      },
      required: true,
      row: "new",
    },
    {
      inputProps: {
        type: "text",
        isdatefield: true,
        className: "form-control border-right-0",
        placeholder: "DD-MM-YY",
        name: "date",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "Date",
        labelProps: { htmlFor: "date", className: "" },
      },
      required: true,
    },
    {
      inputProps: {
        type: "textarea",
        className: "form-control",
        placeholder: "Write here ...",
        name: "comment",
      },
      className: "col-12",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Comment",
        labelProps: { htmlFor: "comment", className: "" },
      },
      required: true,
    },
  ];
};

export const changeCenterInputFixture = (data) => ({
  centerId: data?.centerId || "",
  date: "",
  comment: "",
});

export const quickAddFormFixture = (optionData) => {
  return [
    ...overviewFormFixture(),
    {
      parentProps: {
        input: { className: "form-group mb-4 registration-form" },
      },
      inputProps: {
        type: "file",
        className: "fileChoose",
        name: "image",
        accept: ".jpg,.jpeg,.png",
        id: "formFileLg",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "Image",
        labelProps: { htmlFor: "image", className: "" },
      },
      required: true,
    },
    {
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select",
        name: "ReferredBy",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      options: [
        { text: "Refer To CWC", value: "refer" },
        { text: "Produced To CWC", value: "produce" },
        { text: "Action Pending", value: "action_pending" },
      ],
      label: {
        text: "CWC Action",
        labelProps: { htmlFor: "ReferredBy", className: "" },
      },
      required: true,
    },
    {
      inputProps: {
        type: "text",
        className: "form-control",
        placeholder: "Enter CWC Case No.",
        name: "cwccaseno",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "CWC Case No.",
        labelProps: { htmlFor: "cwccaseno", className: "" },
      },
    },
    {
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select",
        name: "centerdetailId",
      },
      className: "col-12 col-sm-6",
      options: optionData?.centerNames ? [...optionData.centerNames] : [],
      optionProps: { textKey: "name", valueKey: "id" },
      label: {
        text: "Center Name",
        labelProps: { htmlFor: "centerdetailId", className: "" },
      },
      readOnlyField: true,
    },
  ];
};

export const quickAddInputFixture = (userdata) => ({
  ...overviewFormInputFixture,
  ReferredBy: "",
  cwccaseno: "",
  centerdetailId: userdata?.centerId || "",
});

export const followUpFormFixture = () => {
  return [
    {
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select",
        name: "restoration_method",
      },
      className: "col-12 col-sm-6",
      parentClassName: "row mb-3",
      parentProps: { input: { className: "form-group mb-4" } },
      options: [
        { text: "By Telephone/CWC", value: "bytelephone" },
        { text: "By Visit", value: "byvisit" },
        { text: "By E-mail", value: "byemail" },
      ],
      label: {
        text: "Follow-Up Method",
        labelProps: { htmlFor: "restoration_method", className: "" },
      },
      row: "new",
      required: true,
    },
    {
      inputProps: {
        type: "text",
        isdatefield: true,
        className: "form-control border-right-0",
        placeholder: "DD-MM-YY",
        name: "transfer_date",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "Follow-Up Date",
        labelProps: { htmlFor: "transfer_date", className: "" },
      },
      required: true,
    },

    {
      inputProps: {
        type: "text",
        className: "form-control",
        placeholder: "Write here ...",
        name: "comment",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Follow-Up Comment",
        labelProps: { htmlFor: "comment", className: "" },
      },
      required: true,
    },
    {
      parentProps: {
        input: { className: "form-group mb-4 registration-form" },
      },
      inputProps: {
        type: "file",
        className: "fileChoose",
        name: "filename",
        id: "formFileLg",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "Follow-Up Document",
        labelProps: { htmlFor: "filename", className: "" },
      },
      required: false,
    },
  ];
};

export const followUpInputFixture = {
  restoration_method: "",
  transfer_date: "",
  comment: "",
  filename: "",
};

export const followUpValidationField = [
  { key: "restoration_method", errMessage: "Follow up Method is required" },
  { key: "transfer_date", errMessage: "Follow Up Date is required" },
  { key: "comment", errMessage: "Comment is requried" },
];
