import React, { Fragment, useEffect, useState } from "react";
import Form from "../../components/Form/Form";
import {
  followUpFormFixture,
  followUpInputFixture,
  followUpTableHeaderFixture,
  followUpValidationField,
} from "./utilities/fixtures";
import { onChangeInputData } from "../../utilities/helpers";
import TableArea from "../../components/DataTable/TableArea";
import { getDataWithAuth, postDataWithAuth } from "../../_api/apiCall";
import { endpoints } from "../../_api/endpoints";
import { toast } from "react-toastify";

const FollowUps = ({
  title = "",
  documentPath = "",
  audioPath = "",
  getChildInfo = () => {},
  childInfo = {},
  isViewMode = true,
  isEditAllowed = false,
}) => {
  const [inputs, setInputs] = useState([followUpInputFixture]);
  const [tableHeaders] = useState(followUpTableHeaderFixture);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (loading || !childInfo?.data?.id || !handleValidation()) return;
    setLoading(true);
    let formData = new FormData();
    formData.append("childregId", childInfo?.data?.id);
    formData.append("centerId", childInfo?.data?.centerdetailId);

    inputs.forEach((item, index) => {
      for (let key in item) {
        formData.append(`follow_up_data[${index}][${key}]`, item[key]);
      }
    });

    const { success } = await postDataWithAuth({
      api_url: endpoints["follow_up"],
      data: formData,
      contentType: "multipart/form-data",
    });
    setLoading(false);
    if (success) {
      toast.success("Follow Up added successfully");
      setInputs([followUpInputFixture]);
      getTableData("allStatusListing", childInfo?.data?.id, "follow_up");
    } else {
      toast.error("Something went wrong!");
    }
  };

  const handleValidation = () => {
    for (let i = 0; i < inputs.length; i++) {
      const fieldsToValidate = followUpValidationField;
      for (let j = 0; j < fieldsToValidate.length; j++) {
        const { key, errMessage } = fieldsToValidate[j];
        if (!inputs[i][key]) {
          toast.error(errMessage);
          return false;
        }
      }
    }
    return true;
  };

  const addMoreDocuments = () => {
    let c1 = 0;
    for (let i = 0; i < inputs.length; i++) {
      c1++;
    }
    if (c1 === inputs.length) setInputs([...inputs, followUpInputFixture]);
  };

  const handleDeleteFollowUp = async (index) => {
    if (index) {
      setInputs((prev) => prev.filter((_, ind) => index !== ind));
      return;
    }
  };

  const handleDocInputChange = (e, ind) => {
    onChangeInputData(e, inputs, setInputs, {
      index: ind,
    });
  };

  const getTableData = async (endpoint, id, listingFor) => {
    if (loading || !id) return;
    setLoading(true);
    const { success, data } = await getDataWithAuth({
      api_url: listingFor
        ? endpoints[endpoint](listingFor, id)
        : endpoints[endpoint](id),
    });
    setLoading(false);
    if (success) {
      setTableData(data);
    } else {
      toast.error("Something went wrong!");
    }
  };

  useEffect(() => {
    getTableData("allStatusListing", childInfo?.data?.id, "follow_up");
  }, []); // eslint-disable-line

  return (
    <>
      <div className="tab-content p-4" id="pills-tabContent">
        {!isViewMode && isEditAllowed && (
          <div className="registration-form">
            {inputs.map((input, key) => (
              <Fragment key={key}>
                <Form
                  fixture={followUpFormFixture()}
                  inputs={input}
                  loading={loading}
                  onChange={(e) => handleDocInputChange(e, key)}
                  onSubmit={handleSubmit}
                  nextBtnText="Submit"
                  nextBtnClasses="btn-search"
                  showNextBtn={key === inputs.length - 1}
                  showBackBtn={key === inputs.length - 1}
                  backBtnText="Add More"
                  backBtnClasses="btn-add"
                  handleBack={addMoreDocuments}
                  showDeleteBtn={key !== 0}
                  handleDelete={() => handleDeleteFollowUp(key)}
                >
                  <div className="col-12 col-md-4 mb-4">
                    <h5 className="mb-3 mt-2 mt-md-0 mb-md-0 page-title">
                      {`${key + 1} Follow-Up`}
                    </h5>
                  </div>
                </Form>
              </Fragment>
            ))}
          </div>
        )}

        <TableArea
          tableHeadersFixture={tableHeaders}
          data={tableData}
          loading={loading}
          showNotFound={tableHeaders.length > 0}
          filePath={documentPath}
          audioFilePath={audioPath}
          hideAction={childInfo?.action === "view"}
        ></TableArea>
      </div>
    </>
  );
};

export default FollowUps;
