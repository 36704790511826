import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import Form from "../../components/Form/Form";
import { FilterFormFixture, filterInputFixture } from "./utilities/fixtures";
import { onChangeInputData } from "../../utilities/helpers";
import { toast } from "react-toastify";

const FilterProduce = ({
  openModal = false,
  setOpenModal = () => {},
  filterDetails = {},
  setFilterDetails = () => {},
  centerNames = [],
  loading = false,
  getProduceData = () => {},
  logInUser = {},
  applyFilter = () => {},
}) => {
  const [formFixture, setFormFixture] = useState(FilterFormFixture());

  const handleInputChange = (e) => {
    onChangeInputData(e, filterDetails, setFilterDetails);
  };

  const handleModalClose = (reset) => {
    if (reset) setFilterDetails(filterInputFixture(logInUser));
    setOpenModal(false);
  };

  const filterData = async (reset = false) => {
    if (reset) {
      setFilterDetails(filterInputFixture(logInUser));
      applyFilter(false);
      getProduceData(1, false, handleModalClose);
    } else if (handleValidation())
      getProduceData(1, filterDetails, handleModalClose);
  };

  const handleValidation = () => {
    let valid = true;
    let from = filterDetails["from_date"];
    let to = filterDetails["to_date"];
    if (from && to && to < from) {
      valid = false;
      toast.info("From Date Should Be Less Than To Date");
    } else if ((!from && to) || (from && !to)) {
      valid = false;
      let msg = `${
        filterDetails["from_date"] ? "Select To Date" : "Select From Date"
      }`;
      toast.info(msg);
    }
    return valid;
  };

  useEffect(() => {
    setFormFixture(FilterFormFixture({ centerNames: centerNames }));
  }, [centerNames]);

  return (
    <Modal
      open={openModal}
      onClose={() => handleModalClose(true)}
      header="Search Child with Filters"
    >
      <Form
        fixture={formFixture}
        inputs={filterDetails}
        loading={loading}
        onChange={handleInputChange}
        onSubmit={filterData}
        handleBack={filterData}
        showBackBtn={true}
        backBtnText="Reset"
        backBtnClasses="btn-primary-border btn-reset"
        nextBtnText="Search"
        nextBtnClasses="btn-search"
        BtnParentClasses="justify-content-end mt-3"
      />
    </Modal>
  );
};

export default FilterProduce;
