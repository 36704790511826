import React, { useEffect, useState } from "react";
import { userAvatar } from "../../assets/images";
import { profileMenuFixture } from "../../utilities/fixtures";
import { useNavigate } from "react-router-dom";
import { capitalizeWords } from "../../utilities/helpers";
import { getDataWithAuth } from "../../_api/apiCall";
import { endpoints } from "../../_api/endpoints";
import { toast } from "react-toastify";

const ProfileMenu = () => {
  const [userPic, setUserPic] = useState(userAvatar);
  const [showDropdown, setShowDropdown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [userDetails] = useState(
    JSON.parse(localStorage.getItem("user")) || {}
  );

  const navigate = useNavigate();

  const handleAction = (action) => {
    if (action === "Logout") logoutUser();
  };

  const logoutUser = async () => {
    if (loading) return;
    setLoading(true);
    const { success, message } = await getDataWithAuth({
      api_url: endpoints["logout"],
    });
    setLoading(false);
    if (success) {
      localStorage.removeItem("user");
      navigate("/login");
    } else {
      if (message === "Method not allowed.") {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        if (message) toast.error(message);
        else toast.error("Something went wrong!");
      }
    }
  };

  useEffect(() => {
    const handleClick = ({ target }) => {
      if (!target.closest(".dropdown")) setShowDropdown(false);
    };

    window.addEventListener("mousedown", handleClick);

    return () => {
      window.removeEventListener("mousedown", handleClick);
    };
  }, [showDropdown]);

  useEffect(() => {
    if (userDetails?.token && userDetails?.imagePath && userDetails?.image)
      setUserPic(`${userDetails.imagePath}/${userDetails.image}`);
    else setUserPic(userAvatar);
  }, [userDetails]);

  return (
    <>
      <div className={`dropdown-user dropdown ${showDropdown ? "show" : ""}`}>
        <span
          className="nav-link dropdown-toggle  d-flex align-items-center py-0 px-2 px-md-4"
          role="button"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <div className="user-logedin d-flex align-items-center">
            <img
              src={userPic}
              alt="User Avatar"
              className="img-size-50 img-circle mr-2"
            />
            <span className="pl-2">
              {userDetails?.name ? userDetails.name : ""}
              <small>{capitalizeWords(userDetails?.role?.name)}</small>
            </span>
          </div>
        </span>
        <ul
          className={`dropdown-menu user-list shadow-none ${
            showDropdown ? "show" : ""
          }`}
        >
          {profileMenuFixture.map((item, key) => (
            <li key={key}>
              <span
                style={{ cursor: "pointer" }}
                className="dropdown-item"
                onClick={() => handleAction(item.text)}
              >
                <i className={`bi ${item.iconClass}`}></i> {item.text}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default ProfileMenu;
