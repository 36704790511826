import React from "react";

const RolePermission = ({
  text = "",
  keyProp = "",
  permissionDetails = {},
  setPermissionDetails = () => {},
}) => {
  const handleOnChange = ({ target }) => {
    const { name, checked } = target;
    setPermissionDetails((prev) => ({
      ...prev,
      [keyProp]: { ...prev[keyProp], [name]: checked },
    }));
  };

  return (
    <form className="mb-4">
      <div className="form-label">{text}</div>
      {keyProp === "exit_restore" ? (
        <div className="d-flex justify-content-between">
          <label
            htmlFor={`${keyProp}checkbox3`}
            className="option checkbox-blue"
          >
            View
            <input
              type="checkbox"
              id={`${keyProp}checkbox3`}
              name="view"
              checked={!!permissionDetails[keyProp]?.view}
              onChange={handleOnChange}
            />
            <span className="checkbox checkbox3"></span>
          </label>
        </div>
      ) : (
        <div className="d-flex justify-content-between">
          <label
            htmlFor={`${keyProp}checkbox1`}
            className="option checkbox-green"
          >
            All
            <input
              type="checkbox"
              id={`${keyProp}checkbox1`}
              name="all"
              checked={!!permissionDetails[keyProp]?.all}
              onChange={handleOnChange}
            />
            <span className="checkbox checkbox1"></span>
          </label>
          <label
            htmlFor={`${keyProp}checkbox2`}
            className="option checkbox-grey"
          >
            Create
            <input
              type="checkbox"
              id={`${keyProp}checkbox2`}
              name="create"
              checked={!!permissionDetails[keyProp]?.create}
              onChange={handleOnChange}
            />
            <span className="checkbox checkbox2"></span>
          </label>
          <label
            htmlFor={`${keyProp}checkbox3`}
            className="option checkbox-blue"
          >
            View
            <input
              type="checkbox"
              id={`${keyProp}checkbox3`}
              name="view"
              checked={!!permissionDetails[keyProp]?.view}
              onChange={handleOnChange}
            />
            <span className="checkbox checkbox3"></span>
          </label>
          <label
            htmlFor={`${keyProp}checkbox4`}
            className="option checkbox-yellow"
          >
            Edit
            <input
              type="checkbox"
              id={`${keyProp}checkbox4`}
              name="edit"
              checked={!!permissionDetails[keyProp]?.edit}
              onChange={handleOnChange}
            />
            <span className="checkbox checkbox4"></span>
          </label>
          <label
            htmlFor={`${keyProp}checkbox5`}
            className="option checkbox-red"
          >
            Delete
            <input
              type="checkbox"
              id={`${keyProp}checkbox5`}
              name="delete"
              checked={!!permissionDetails[keyProp]?.delete}
              onChange={handleOnChange}
            />
            <span className="checkbox checkbox5"></span>
          </label>
        </div>
      )}
    </form>
  );
};

export default RolePermission;
