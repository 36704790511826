import React from "react";
import {
  capitalizeWords,
  getFormattedDate,
  getStatusColorCode,
  handleFileDownload,
  isActionAllowed,
} from "../../utilities/helpers";
import { userAvatar } from "../../assets/images";

const DataTable = ({
  tableHeadersFixture = [],
  data = [],
  loading = false,
  imagePath = "",
  onEdit = () => {},
  onDelete = () => {},
  onSetPermission = () => {},
  onView = () => {},
  onGoTo = () => {},
  onStatusChange = () => {},
  onSelectRow = () => {},
  selectedRows = {},
  keyname = "",
  getKeyVal = () => {},
  startSerialNum = 1,
  filePath = "",
  audioFilePath = "",
  deleteLastOnly = false,
  hideAction = false,
}) => {
  const GetTableData = (rowData, ind) =>
    tableHeadersFixture.map(
      (
        {
          action,
          formatDate,
          key: headerKey,
          objkey,
          profile,
          actionFor,
          fileKeyname,
          audioFile,
          formatVal,
          fn,
        },
        key
      ) => (
        <td key={key}>
          {action &&
          (!hideAction ||
            headerKey.includes("view") ||
            headerKey.includes("viewFile")) ? (
            <div>
              <div className="d-flex justify-content-center">
                {headerKey.includes("view") ? (
                  <span
                    className="btn btn-view"
                    onClick={() => (loading ? {} : onView(rowData))}
                  >
                    <i className="bi bi-eye"></i>
                  </span>
                ) : null}
                {headerKey.includes("goTo") &&
                isActionAllowed("create", actionFor) ? (
                  <span
                    className="btn btn-view"
                    style={{ backgroundColor: "#3c3e50" }}
                    onClick={() => (loading ? {} : onGoTo(rowData))}
                  >
                    <i className="bi bi-box-arrow-in-right"></i>
                  </span>
                ) : null}
                {headerKey.includes("viewFile") ? (
                  rowData?.["documents"]?.[0]?.["filename"] ||
                  (fileKeyname && rowData?.[fileKeyname]) ? (
                    <span className="btn btn-view">
                      <a
                        href={
                          audioFile
                            ? `${audioFilePath}/${rowData[fileKeyname]}`
                            : fileKeyname
                            ? `${filePath}/${rowData[fileKeyname]}`
                            : `${filePath}/${rowData?.["documents"][0]?.["filename"]}`
                        }
                        target="_blank"
                        rel="noreferrer"
                        className="bi bi-eye text-white"
                      >
                        {" "}
                      </a>
                    </span>
                  ) : (
                    "-"
                  )
                ) : null}
                {headerKey.includes("setPermission") &&
                isActionAllowed("setPermission", actionFor) ? (
                  <span
                    className="btn btn-lock"
                    onClick={() => (loading ? {} : onSetPermission(rowData))}
                  >
                    <i className="bi bi-lock"></i>
                  </span>
                ) : null}
                {headerKey.includes("edit") &&
                isActionAllowed("edit", actionFor) ? (
                  <span
                    className="btn btn-edit"
                    onClick={() => (loading ? {} : onEdit(rowData))}
                  >
                    <i className="bi bi-pencil"></i>
                  </span>
                ) : null}
                {headerKey.includes("delete") &&
                (deleteLastOnly ? data.length - 1 === ind : true) &&
                isActionAllowed("delete", actionFor) ? (
                  <span
                    className="btn btn-delete"
                    onClick={() => (loading ? {} : onDelete(rowData))}
                  >
                    <i className="bi bi-trash"></i>
                  </span>
                ) : null}
                {headerKey.includes("downloadFile") ? (
                  fileKeyname && rowData?.[fileKeyname] ? (
                    <span
                      className="btn btn-view"
                      onClick={() =>
                        loading
                          ? {}
                          : handleFileDownload(
                              audioFile
                                ? `${audioFilePath}/${rowData[fileKeyname]}`
                                : fileKeyname
                                ? `${filePath}/${rowData[fileKeyname]}`
                                : ``
                            )
                      }
                    >
                      <i className="bi bi-download"></i>
                    </span>
                  ) : (
                    ""
                  )
                ) : null}
              </div>
            </div>
          ) : objkey && headerKey === "history_status" ? (
            Array.isArray(rowData[objkey]) ? (
              rowData[objkey][rowData[objkey].length - 1]?.[headerKey] ? (
                <span
                  className="tag-corner"
                  style={{
                    color: getStatusColorCode(
                      rowData[objkey][rowData[objkey].length - 1]?.[headerKey]
                    ),
                  }}
                >
                  {capitalizeWords(
                    rowData[objkey][rowData[objkey].length - 1]?.[headerKey],
                    "_"
                  )}
                </span>
              ) : (
                "-"
              )
            ) : rowData[objkey]?.[headerKey] ? (
              <span
                className="tag-corner"
                style={{
                  color: getStatusColorCode(rowData[objkey]?.[headerKey]),
                }}
              >
                {capitalizeWords(rowData[objkey]?.[headerKey], "_")}
              </span>
            ) : (
              "-"
            )
          ) : objkey ? (
            Array.isArray(rowData[objkey]) ? (
              rowData[objkey][rowData[objkey].length - 1]?.[headerKey] ? (
                rowData[objkey][rowData[objkey].length - 1]?.[headerKey]
              ) : (
                "-"
              )
            ) : rowData[objkey]?.[headerKey] ? (
              rowData[objkey]?.[headerKey]
            ) : (
              "-"
            )
          ) : profile ? (
            <>
              <div className="user-logedin d-flex align-items-center">
                {rowData["image"] ? (
                  <img
                    className="user_icon img-size-50 img-circle mr-2"
                    style={{ objectFit: "cover", aspectRatio: "1/1" }}
                    src={`${imagePath}/${rowData["image"]}`}
                    alt=""
                  ></img>
                ) : (
                  <img className="user_icon mr-2" src={userAvatar} alt=""></img>
                )}
                <span
                  className="view_btn"
                  role="button"
                  onClick={() => onView(rowData)}
                >
                  {rowData[headerKey]}
                </span>
              </div>
            </>
          ) : headerKey === "select_row" ? (
            <div>
              <input
                type="checkbox"
                id={rowData["id"]}
                name={rowData["id"]}
                checked={!!selectedRows[rowData["id"]]}
                onChange={(e) => (loading ? {} : onSelectRow(e, rowData))}
              />
              <label className="switch" htmlFor={rowData["id"]}></label>
            </div>
          ) : headerKey === "status" ? (
            <div className="custom-checkbox">
              <input
                type="checkbox"
                hidden="hidden"
                id={rowData["id"]}
                name="status"
                disabled={!isActionAllowed("edit")}
                checked={!!rowData[headerKey]}
                onChange={(e) => (loading ? {} : onStatusChange(e, rowData))}
              />
              <label className="switch" htmlFor={rowData["id"]}></label>
            </div>
          ) : headerKey === "child_status" ? (
            rowData["ReferredBy"] ? (
              rowData["ReferredBy"] === "produce" && rowData["produce_cwc"] ? (
                <span className="tag-corner">Produced to CWC</span>
              ) : rowData["ReferredBy"] === "refer" && rowData["refer_cwc"] ? (
                <span className="tag-corner">Referred from CWC</span>
              ) : (
                <span className="tag-corner">New Corner</span>
              )
            ) : (
              "-"
            )
          ) : headerKey === "p_child_status" ||
            headerKey === "r_child_status" ? (
            rowData["produce_cwc"] ? (
              <span className="tag-corner">Produced to CWC</span>
            ) : rowData["refer_cwc"] ? (
              <span className="tag-corner">Referred from CWC</span>
            ) : (
              <span className="tag-corner">New Corner</span>
            )
          ) : fn && typeof fn === "function" ? (
            fn(rowData)
          ) : formatDate ? (
            getFormattedDate(rowData[headerKey], true)
          ) : keyname && headerKey === keyname ? (
            getKeyVal(rowData[keyname])
          ) : headerKey === "serial_num" ? (
            ind + startSerialNum
          ) : formatVal ? (
            capitalizeWords(rowData[headerKey], "_")
          ) : rowData[headerKey] ? (
            rowData[headerKey]
          ) : (
            "-"
          )}
        </td>
      )
    );

  return (
    <>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              {tableHeadersFixture.map((header, key) => (
                <th key={key}>{header.text}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, key) => (
              <tr key={key}>{GetTableData(item, key)}</tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DataTable;
