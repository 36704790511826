import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import { onChangeInputData } from "../../utilities/helpers";
import { userDetailsFixture } from "./utilities/fixture";
import validate from "../../utilities/validation";
import { toast } from "react-toastify";
import { postDataWithAuth } from "../../_api/apiCall";
import { endpoints } from "../../_api/endpoints";
import { userAvatar } from "../../assets/images";

const AddUser = ({
  loading = false,
  setLoading = () => {},
  openModal = false,
  centerNames = [],
  roles = [],
  imagePath = "",
  setOpenModal = () => {},
  userDetails = {},
  setUserDetails = () => {},
  cb = () => {},
  logInUser = {},
}) => {
  const [userPic, setUserPic] = useState(userAvatar);

  const handleSubmit = async () => {
    if (!loading && handleValidation()) {
      setLoading(true);
      let formData = new FormData();
      for (let key in userDetails) {
        let keyData = userDetailsFixture();
        if ((key in keyData || key === "id") && userDetails[key])
          formData.append(key, userDetails[key]);
      }

      const { success, errors, message } = await postDataWithAuth({
        api_url: userDetails["id"]
          ? endpoints["updateUser"]
          : endpoints["addUser"],
        data: formData,
        contentType: "multipart/form-data",
      });
      setLoading(false);
      if (success) {
        if (message) toast.success(message);
        if (cb) cb();
        setOpenModal(false);
        setUserDetails(userDetailsFixture(logInUser));
      } else if (errors) {
        if (typeof errors === "object")
          toast.error(errors[Object.keys(errors)[0]][0]);
      } else {
        toast.error(message);
      }
    }
  };

  const handleValidation = () => {
    let validation = true;

    for (let key in userDetails) {
      let keyData = userDetailsFixture();
      if (key in keyData) {
        if (!(userDetails["id"] && key === "password")) {
          let err = validate[key](userDetails[key]);
          if (!!err) {
            toast.error(err);
            return false;
          }
        }
      }
    }

    return validation;
  };

  const handleClose = () => {
    setUserDetails(userDetailsFixture(logInUser));
    setOpenModal(false);
  };

  useEffect(() => {
    if (userDetails.image && typeof userDetails.image === "object")
      setUserPic(URL.createObjectURL(userDetails.image));
    else if (userDetails?.id && imagePath && userDetails.image)
      setUserPic(`${imagePath}/${userDetails.image}`);
    else setUserPic(userAvatar);
  }, [userDetails, imagePath]);

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      header={userDetails["id"] ? "Update User" : "Add User"}
      modal_classes="add-role-popup"
    >
      <form>
        <div className="mb-3 row">
          <div className="col-12 col-sm-6">
            <label>Name</label>
            <div className="form-group mb-3">
              <input
                type="text"
                name="name"
                value={userDetails.name}
                className="form-control"
                placeholder="Enter Name"
                onChange={(e) =>
                  onChangeInputData(e, userDetails, setUserDetails)
                }
              />
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <label htmlFor="email">Email ID</label>
            <div className="form-group input-group mb-3">
              <input
                type="email"
                name="email"
                disabled={userDetails["id"]}
                value={userDetails.email}
                className="form-control"
                placeholder="Enter your email id"
                onChange={(e) =>
                  userDetails["id"]
                    ? {}
                    : onChangeInputData(e, userDetails, setUserDetails)
                }
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-envelope"></span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <label htmlFor="password">Password</label>
            <div className="form-group input-group mb-3">
              <input
                name="password"
                type="password"
                value={userDetails.password || ""}
                className="form-control"
                placeholder="Enter your password"
                onChange={(e) =>
                  onChangeInputData(e, userDetails, setUserDetails)
                }
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-lock"></span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <label>Status</label>
            <div className="form-group mb-4">
              <div className="custom_select">
                <select
                  className="form-control"
                  name="status"
                  value={userDetails.status}
                  onChange={(e) =>
                    onChangeInputData(e, userDetails, setUserDetails)
                  }
                >
                  <option value={1}>Enable</option>
                  <option value={0}>Disable</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <label>Phone No.</label>
            <div className="form-group mb-4">
              <input
                type="text"
                name="mobile_number"
                value={userDetails.mobile_number}
                className="form-control"
                placeholder="Enter Phone No."
                onChange={(e) =>
                  onChangeInputData(e, userDetails, setUserDetails)
                }
              />
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <label>Joining Date</label>
            <div className="form-group input-group mb-3">
              <input
                type="date"
                name="join_date"
                value={userDetails.join_date || ""}
                className="form-control"
                placeholder="DD-MM-YY"
                onChange={(e) =>
                  onChangeInputData(e, userDetails, setUserDetails)
                }
              />
              {/* <span className="input-group-text bg-white border-left-0  ml-n1 ">
                {" "}
                <i className="bi bi-calendar4"></i>
              </span> */}
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <label>Image</label>
            <div className="form-group mb-3 d-flex">
              <img
                src={userPic}
                alt="User Avatar"
                className="img-size-50 img-circle mr-2"
              />
              <input
                className="form-control"
                type="file"
                name="image"
                accept=".jpg, .png, .jpeg"
                onChange={(e) =>
                  onChangeInputData(e, userDetails, setUserDetails)
                }
              />
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <label>Comment</label>
            <div className="form-group mb-3">
              <input
                type="text"
                name="comment"
                value={userDetails.comment}
                className="form-control"
                placeholder="Enter comment"
                onChange={(e) =>
                  onChangeInputData(e, userDetails, setUserDetails)
                }
              />
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <label>
              Center <span className="required">*</span>
            </label>
            <div className="form-group mb-4">
              <div className="custom_select">
                <select
                  className="form-control"
                  name="centertypeId"
                  value={userDetails.centertypeId}
                  onChange={(e) =>
                    onChangeInputData(e, userDetails, setUserDetails)
                  }
                >
                  <option value="">Select</option>
                  {centerNames &&
                    centerNames.map((center, key) => (
                      <option key={key} value={center?.id}>
                        {center?.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <label>
              Role <span className="required">*</span>
            </label>
            <div className="form-group mb-4">
              <div className="custom_select">
                <select
                  className="form-control"
                  name="role_id"
                  value={userDetails.role_id}
                  onChange={(e) =>
                    onChangeInputData(e, userDetails, setUserDetails)
                  }
                >
                  <option value="">Select</option>
                  {roles &&
                    roles.map((role, key) => (
                      <option key={key} value={role?.id}>
                        {role?.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-12 ">
            <div className="button-group display-flex justify-content-end mt-5">
              <button
                type="button"
                className="btn btn-primary btn-primary-border "
                disabled={loading}
                onClick={handleClose}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                disabled={loading}
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default AddUser;
