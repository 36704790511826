import React, { Fragment, useEffect, useState } from "react";
// import { notificationsFixture } from "../../utilities/fixtures";
import { getDataWithAuth } from "../../_api/apiCall";
import { endpoints } from "../../_api/endpoints";
import { useNavigate } from "react-router-dom";

const Notifications = () => {
  const [logInUser] = useState(JSON.parse(localStorage.getItem("user")) || {});
  const [show, setShow] = useState(false);
  const [notifyCount, setNotifyCount] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getNotification = async (centerId) => {
    if (loading || !centerId) return;
    setLoading(true);
    const { success, data } = await getDataWithAuth({
      api_url: endpoints["notifyListCount"](centerId),
    });
    setLoading(false);
    if (success && data) setNotifyCount(data);
    else setNotifyCount({});
  };

  const viewAll = async (centerId) => {
    navigate("/notifications");
    setShow(false);
  };

  useEffect(() => {
    const handleClickOutside = ({ target }) => {
      if (!target.closest(".bellicon") && !target.closest(".dropdown-menu")) {
        setShow(false);
      }
    };

    if (show && logInUser?.centerId) {
      getNotification(logInUser.centerId);
    }

    window.addEventListener("mousedown", handleClickOutside, false);

    return () => {
      window.removeEventListener("mousedown", handleClickOutside, false);
    };
  }, [show]); // eslint-disable-line

  return (
    <>
      <span
        className="nav-link dropdown-toggle bellicon p-0"
        id="notification"
        role="button"
        onClick={() => setShow(!show)}
      >
        <i className="fas fa-bell"></i>
        <span className="online"></span>
      </span>
      {show && (
        <div
          className="dropdown-menu dropdown-menu-lg dropdown-menu-sm-right shadow-none"
          aria-labelledby="notification"
          style={{ display: "block" }}
        >
          <div className="dropdown-item dropdown-header d-flex align-items-center">
            <span className="text-left text-noti">Notifications</span>
            <span
              className="view-link ml-auto"
              role="button"
              onClick={() => viewAll(logInUser?.centerId)}
            >
              View All
            </span>
          </div>
          {/* {notificationsFixture.map((item, key) => (
            <Fragment key={key}>
              <div className="dropdown-divider"></div>
              <span className="dropdown-item">
                <i className={`fas mr-2 ${item.iconClass}`}></i> {item.num}{" "}
                {item.text}
                <span className="float-right text-muted text-sm">
                  {item.duration}
                </span>
              </span>
            </Fragment>
          ))} */}

          <div className="dropdown-divider"></div>
          <span className="dropdown-item">
            <i className={`fas mr-2 fa-envelope`}></i>{" "}
            {notifyCount?.record_count || `0`} new messages
            <span className="float-right text-muted text-sm">
              {notifyCount?.timeDiff || ""}
            </span>
          </span>

          <div className="dropdown-divider"></div>
        </div>
      )}
    </>
  );
};

export default Notifications;
