import { isActionAllowed } from "./helpers";

export const errors = {
  "Failed to fetch": "Server not responding. Please try again later.",
};

export const loginFixture = {
  emailId: "",
  password: "",
};

export const notificationsFixture = [
  {
    iconClass: "fa-envelope",
    num: 4,
    text: "new messages",
    duration: "3 mins",
    notifyFor: "messages",
  },
  {
    iconClass: "fa-users",
    num: 4,
    text: "friend requests",
    duration: "12 hours",
    notifyFor: "friend_requests",
  },
  {
    iconClass: "fa-file",
    num: 4,
    text: "new reports",
    duration: "2 days",
    notifyFor: "reports",
  },
];

export const profileMenuFixture = [
  // {
  //   iconClass: "bi-person",
  //   text: "User Profile",
  // },
  {
    iconClass: "bi-power",
    text: "Logout",
  },
];

// export const sidebarFixture = [
//   {
//     link: "/dashboard",
//     iconClass: "bi-house",
//     text: "Dashboard",
//     isActive: true,
//   },
//   {
//     link: "/child-registration",
//     iconClass: "bi-child-registration",
//     text: "Child Registration Management",
//   },
//   {
//     link: "/produce",
//     iconClass: "bi-cwc",
//     text: "CWC Management",
//     childLinks: [
//       {
//         link: "/produce",
//         iconClass: "far-product",
//         text: "Produce to CWC",
//       },
//       {
//         link: "/refer",
//         iconClass: "far-refer",
//         text: "Refer from CWC",
//       },
//     ],
//   },
//   {
//     link: "/center-management",
//     iconClass: "bi-gear",
//     text: "Our Services",
//     childLinks: [
//       {
//         link: "/center-management",
//         iconClass: "far-center",
//         text: "Center Management",
//       },
//       {
//         link: "/tag-management",
//         iconClass: "far-center",
//         text: "Tag Management",
//       },
//       {
//         link: "/meals-management",
//         iconClass: "far-meals",
//         text: "Meals Management",
//       },
//       // {
//       //   // link: "#",
//       //   iconClass: "far-educaton",
//       //   text: "Education",
//       //   modal: "education",
//       // },
//       // {
//       //   // link: "#",
//       //   iconClass: "far-medical",
//       //   text: "Medical",
//       //   modal: "medical",
//       // },
//       // {
//       //   // link: "#",
//       //   iconClass: "far-mhp",
//       //   text: "MHP",
//       //   modal: "mhp",
//       // },
//     ],
//   },
//   {
//     link: "/dropout",
//     iconClass: "bi_exit",
//     text: "Exit Management",
//     childLinks: [
//       {
//         link: "/dropout",
//         iconClass: "far-dropout",
//         text: "Dropout",
//       },
//       {
//         link: "/run-away",
//         iconClass: "fa-runway",
//         text: "Run Away",
//       },
//       {
//         link: "/short-stay",
//         iconClass: "fa-short",
//         text: "short Stay",
//       },
//       {
//         link: "/transfer",
//         iconClass: "fa-transfer",
//         text: "Transfer",
//       },
//       {
//         link: "/other-ngo",
//         iconClass: "fa-other-ngo",
//         text: "Other NGO",
//       },
//       {
//         link: "/io-police",
//         iconClass: "fa-io-police",
//         text: "IO-Police",
//       },
//       {
//         link: "/transfer-to-home-state-cwc",
//         iconClass: "fa-tthome",
//         text: "Transfer to Home State CWC",
//       },
//     ],
//   },
//   {
//     link: "/rehabilitation-set-free",
//     iconClass: "bi-restoration",
//     text: "Restoration Management",
//     childLinks: [
//       {
//         link: "/rehabilitation-set-free",
//         iconClass: "far-rehabilitation",
//         text: "Rehabilitation/Set-free",
//       },
//       // {
//       //   link: "/run-away",
//       //   iconClass: "fa-runay",
//       //   text: "Runway",
//       // },
//       {
//         link: "/after-care",
//         iconClass: "fa-care",
//         text: "After Care",
//       },
//       {
//         link: "/higher-education",
//         iconClass: "fa-education",
//         text: "Higher Education",
//       },
//     ],
//   },
//   {
//     link: "/roles-management",
//     iconClass: "bi-roles",
//     text: "Roles Management",
//   },
//   {
//     link: "/users-management",
//     iconClass: "bi-user",
//     text: "Users Management",
//   },
//   {
//     link: "/aboutus",
//     iconClass: "bi-about",
//     text: "About Us",
//   },
//   {
//     link: "/groupReports",
//     iconClass: "bi-document",
//     text: "Group Report",
//   },
//   {
//     link: "/reports",
//     iconClass: "bi-document",
//     text: "Reports",
//   },
// ];

export const headerTextFixture = [
  { link: "/dashboard", text: "Dashboard" },
  { link: "/child-registration", text: "Child Registration List" },
  { link: "/child-registration/profile", text: "Child Registration" },
  {
    link: "/center-management",
    text: "Centers Management",
    pageTitle: "Our Services",
  },
  {
    link: "/tag-management",
    text: "Tags Management",
    pageTitle: "Our Services",
  },
  { link: "/meals-management", text: "Our Services" },
  { link: "/education", text: "Education" },
  { link: "/medical", text: "Medical" },
  { link: "/mhp", text: "MHP" },
  { link: "/dropout", text: "Exit Management" },
  { link: "/restoration", text: "Restoration Management" },
  { link: "/restoration-followup", text: "Restoration Management" },
  { link: "/rehabilitation-set-free", text: "Restoration Management" },
  { link: "/roles-management", text: "Roles Management" },
  { link: "/users-management", text: "Users Management" },
  { link: "/aboutus", text: "About Us" },
  { link: "/produce", text: "CWC Management" },
  { link: "/groupReports", text: "Group Report" },
  { link: "/reports", text: "Reports" },
  { link: "/run-away", text: "Exit Management" },
  { link: "/after-care", text: "Restoration Management" },
  { link: "/higher-education", text: "Restoration Management" },
  { link: "/short-stay", text: "Restoration Management" },
  { link: "/short-stay-register", text: "Restoration Management" },
  { link: "/transfer", text: "Restoration Management" },
  { link: "/other-ngo", text: "Exit Management" },
  { link: "/io-police", text: "Exit Management" },
  { link: "/home-state-cwc-transfer", text: "Exit Management" },
  { link: "/produce/add", text: "CWC Management" },
  { link: "/refer", text: "CWC Management" },
  { link: "/refer/add", text: "CWC Management" },
  { link: "/center-management/add-center", text: "Centers Management" },
  { link: "/reports/report-details", text: "Reports" },
  { link: "/notifications", text: "Notifications" },
  { link: "/cwc-dropout", text: "CWC Management" },
  { link: "/cwc-after-care", text: "CWC Management" },
  { link: "/cwc-higher-education", text: "CWC Management" },
  { link: "/cwc-rehabilitation", text: "CWC Management" },
  { link: "/cwc-run-away", text: "CWC Management" },
  { link: "/cwc-short-stay", text: "CWC Management" },
  { link: "/cwc-by-police", text: "Exit Management" },
  { link: "/cwc-by-staff", text: "Exit Management" },
  { link: "/cwc-by-third-party", text: "Exit Management" },
  { link: "/cwc-rescue", text: "Restoration Management" },
  { link: "/cwc-other-cci", text: "Exit Management" },
];

export const sidebarLinkFixture = () => {
  let userData = JSON.parse(localStorage.getItem("user")) || {};
  let isAdmin =
    userData?.role?.name === "superadmin" || userData?.role?.name === "admin";
  let isViewer = userData?.role?.name === "viewer";
  let arr1 = [
    {
      link: "/dashboard",
      iconClass: "bi-house",
      text: "Dashboard",
      isActive: true,
    },
    {
      link: "/child-registration",
      iconClass: "bi-child-registration",
      text: "Child Registration List",
    },
    {
      link: "/produce",
      iconClass: "bi-cwc",
      text: "CWC Management",
      childLinks: [
        {
          link: "/produce",
          iconClass: "far-product",
          text: "Produce to CWC",
        },
        {
          link: "/refer",
          iconClass: "far-refer",
          text: "Refer from CWC",
        },
        // {
        //   link: "/cwc-dropout",
        //   iconClass: "far-dropout",
        //   text: "Dropout",
        // },
        // {
        //   link: "/cwc-after-care",
        //   iconClass: "fa-care",
        //   text: "After Care",
        // },
        // {
        //   link: "/cwc-higher-education",
        //   iconClass: "fa-education",
        //   text: "Higher Education",
        // },
        // {
        //   link: "/cwc-rehabilitation",
        //   iconClass: "far-rehabilitation",
        //   text: "Rehabilitation/Set-free",
        // },
        // {
        //   link: "/cwc-short-stay",
        //   iconClass: "fa-short",
        //   text: "short Stay",
        // },
        // {
        //   link: "/cwc-run-away",
        //   iconClass: "fa-runway",
        //   text: "Run Away",
        // },
        // {
        //   link: "/cwc-rescue",
        //   iconClass: "far-product",
        //   text: "Rescue",
        // },
        // {
        //   link: "/cwc-other-cci",
        //   iconClass: "far-product",
        //   text: "Other CCI",
        // },
        // {
        //   link: "/cwc-by-police",
        //   iconClass: "fa-io-police",
        //   text: "By Police",
        // },
        // {
        //   link: "/cwc-by-staff",
        //   iconClass: "far-product",
        //   text: "By SBT Staff",
        // },
        // {
        //   link: "/cwc-by-third-party",
        //   iconClass: "far-product",
        //   text: "By Third Party",
        // },
      ],
    },
  ];

  if (isAdmin || isViewer || isActionAllowed("view", "exit_restore")) {
    arr1.push(
      {
        link: "/dropout",
        iconClass: "bi_exit",
        text: "Exit Management",
        childLinks: [
          {
            link: "/dropout",
            iconClass: "far-dropout",
            text: "Dropout",
          },
          {
            link: "/run-away",
            iconClass: "fa-runway",
            text: "Run Away",
          },

          {
            link: "/other-ngo",
            iconClass: "fa-other-ngo",
            text: "SBT Home",
          },
          {
            link: "/cwc-other-cci",
            iconClass: "far-product",
            text: "Other CCI",
          },
          {
            link: "/io-police",
            iconClass: "fa-io-police",
            text: "IO-Police",
          },
          {
            link: "/home-state-cwc-transfer",
            iconClass: "fa-tthome",
            text: "Transfer to Home State CWC",
          },
          {
            link: "/cwc-by-police",
            iconClass: "fa-io-police",
            text: "By Police",
          },
          {
            link: "/cwc-by-staff",
            iconClass: "far-product",
            text: "By SBT Staff",
          },
          {
            link: "/cwc-by-third-party",
            iconClass: "far-product",
            text: "By Third Party",
          },
        ],
      },
      {
        link: "/restoration",
        iconClass: "bi-restoration",
        text: "Restoration Management",
        childLinks: [
          {
            link: "/restoration",
            iconClass: "far-rehabilitation",
            text: "Restoration",
          },
          {
            link: "/restoration-followup",
            iconClass: "far-rehabilitation",
            text: "Restoration/Follow-Up",
          },
          {
            link: "/followup",
            iconClass: "far-rehabilitation",
            text: "Follow-Up",
          },
          {
            link: "/rehabilitation-set-free",
            iconClass: "far-rehabilitation",
            text: "Rehabilitation/Set-free",
          },
          // {
          //   link: "/run-away",
          //   iconClass: "fa-runay",
          //   text: "Runway",
          // },
          {
            link: "/after-care",
            iconClass: "fa-care",
            text: "After Care",
          },
          {
            link: "/higher-education",
            iconClass: "fa-education",
            text: "Higher Education",
          },
          {
            link: "/cwc-rescue",
            iconClass: "far-product",
            text: "Rescue",
          },
          {
            link: "/short-stay",
            iconClass: "fa-short",
            text: "Short Stay Action",
          },
          {
            link: "/short-stay-register",
            iconClass: "fa-short",
            text: "Short Stay Registration",
          },
          {
            link: "/transfer",
            iconClass: "fa-transfer",
            text: "Transfer",
          },
        ],
      }
    );
  }
  if (isAdmin || isViewer) {
    arr1.push({
      link: isViewer ? "/meals-management" : "/center-management",
      iconClass: "bi-gear",
      text: "Our Services",
      childLinks: [
        ...(isViewer
          ? []
          : [
              {
                link: "/center-management",
                iconClass: "far-center",
                text: "Center Management",
              },
              {
                link: "/tag-management",
                iconClass: "far-center",
                text: "Tag Management",
              },
            ]),
        {
          link: "/meals-management",
          iconClass: "far-meals",
          text: "Meals Management",
        },
        // {
        //   // link: "#",
        //   iconClass: "far-educaton",
        //   text: "Education",
        //   modal: "education",
        // },
        // {
        //   // link: "#",
        //   iconClass: "far-medical",
        //   text: "Medical",
        //   modal: "medical",
        // },
        // {
        //   // link: "#",
        //   iconClass: "far-mhp",
        //   text: "MHP",
        //   modal: "mhp",
        // },
        {
          link: "/education",
          iconClass: "far-educaton",
          text: "Education",
        },
        {
          link: "/medical",
          iconClass: "far-medical",
          text: "Medical",
        },
        {
          link: "/mhp",
          iconClass: "far-mhp",
          text: "MHP",
        },
      ],
    });
  }
  if (isAdmin) {
    arr1.push(
      {
        link: "/roles-management",
        iconClass: "bi-roles",
        text: "Roles Management",
      },
      {
        link: "/users-management",
        iconClass: "bi-user",
        text: "Users Management",
      }
    );
  }
  let arr2 = [
    {
      link: "/aboutus",
      iconClass: "bi-about",
      text: "About Us",
    },
  ];
  if (isAdmin || isViewer) {
    arr2.push(
      {
        link: "/groupReports",
        iconClass: "bi-document",
        text: "Group Report",
      },
      {
        link: "/reports",
        iconClass: "bi-document",
        text: "Reports",
      }
    );
  }
  return [...arr1, ...arr2];
};
