import { getDataWithAuth, postDataWithAuth } from "../_api/apiCall";
import { endpoints } from "../_api/endpoints";

export const onChangeInputData = (
  event,
  data,
  setData,
  other = { numberFields: [], index: 0 }
) => {
  const { name, value, type, files } = event.target;
  let transformedValue = value;

  if (typeof value === "string") {
    transformedValue = value.trimStart().replace(/\s+/g, " ");
  }
  if (name === "email") {
    transformedValue = value.trim();
  } else if (
    [
      "phone",
      "age",
      "income",
      "mobileno",
      "phoneno",
      "aadharno",
      "pincode",
      ...(other?.numberFields ? other.numberFields : []),
    ].includes(name)
  ) {
    transformedValue = value.replace(/\D/g, "");
  }
  if (type === "file") {
    transformedValue = files[0]?.size ? files[0] : "";
  }

  if (Array.isArray(data)) {
    setData((prev) =>
      prev.map((item, ind) =>
        ind === other.index
          ? {
              ...item,
              [name]: transformedValue,
              ...(other?.overrideKeys ? other.overrideKeys : {}),
            }
          : item
      )
    );
    return;
  }

  let obj = {
    ...data,
    [name]: transformedValue,
  };
  if (other?.overrideKeys) obj = { ...obj, ...other.overrideKeys };
  setData(obj);
  return obj;
};

export const getFormattedDate = (dateString = new Date(), dateTime = false) => {
  const date = new Date(dateString);

  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();

  if (month < 10) month = `0${month}`;
  if (day < 10) day = `0${day}`;
  if (hours < 10) hours = `0${hours}`;
  if (minutes < 10) minutes = `0${minutes}`;
  if (seconds < 10) seconds = `0${seconds}`;

  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  if (!dateTime) return formattedDate.split(" ")[0];

  return formattedDate;
};

export const getFileExtension = (fileName) => {
  const parts = fileName.split(".");
  if (parts.length > 1) {
    return parts.pop().toLowerCase();
  }
  return "";
};

export const getCountryList = async () => {
  const { data } = await getDataWithAuth({
    api_url: endpoints["getCountries"],
  });
  if (data) return data;
};

export const getStateList = async (countryId) => {
  if (!countryId) return;
  const { data } = await getDataWithAuth({
    api_url: endpoints["getStates"](countryId),
  });
  if (data) return data;
};

export const getCityList = async (stateId) => {
  if (!stateId) return;
  const { data } = await getDataWithAuth({
    api_url: endpoints["getCities"](stateId),
  });
  if (data) return data;
};

export const getDistrictList = async (stateId) => {
  if (!stateId) return;
  const { data } = await getDataWithAuth({
    api_url: endpoints["getDistricts"](stateId),
  });
  if (data) return data;
};

export const getCenterPoints = async () => {
  const { data } = await getDataWithAuth({
    api_url: endpoints["centers_points"],
  });
  if (data) return data;
};

export const getTagNames = async () => {
  const { data } = await getDataWithAuth({
    api_url: endpoints["getAllTags"],
  });
  if (data) return data;
};

export const getCenterNames = async () => {
  const { data } = await getDataWithAuth({
    api_url: endpoints["getCenterNames"],
  });
  if (data) return data;
};

export const isActionAllowed = (action, actionFor) => {
  let userData = JSON.parse(localStorage.getItem("user")) || {};
  if (userData?.role?.name === "superadmin" || userData?.role?.name === "admin")
    return true;
  let temp = {
    all: 0,
    create: 0,
    edit: 0,
    view: 1,
    delete: 0,
  };
  let child_registration = userData?.role?.child_registration
    ? JSON.parse(userData?.role?.child_registration)
    : temp;
  let child_tracking = userData?.role?.child_tracking
    ? JSON.parse(userData?.role?.child_tracking)
    : temp;
  let cwc = userData?.role?.cwc ? JSON.parse(userData?.role?.cwc) : temp;
  let exit_restore = userData?.role?.exit_restore
    ? JSON.parse(userData?.role?.exit_restore)
    : { view: 0 };

  if (!actionFor) {
    return temp[action];
  } else if (actionFor === "child_registration") {
    return child_registration["all"] || child_registration[action];
  } else if (actionFor === "child_tracking") {
    return child_tracking["all"] || child_tracking[action];
  } else if (actionFor === "cwc") {
    return cwc["all"] || cwc[action];
  } else if (actionFor === "exit_restore") {
    return exit_restore[action] || userData?.role?.name === "viewer";
  } else return false;
};

export const capitalizeWords = (word, separator = " ") => {
  if (!word) return "-";
  return word
    .split(separator)
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
    .join(" ");
};

export const getEducationOptions = async () => {
  let arr = [];
  const { success, education_list } = await getDataWithAuth({
    api_url: endpoints["getEducationOptions"],
  });
  if (success) {
    if (education_list) {
      for (let key in education_list) {
        arr.push({ text: education_list[key], value: key });
      }
    }
  }
  return arr;
};

export const getMedicalOptions = async () => {
  let arr = [];
  const { success, medical_list } = await getDataWithAuth({
    api_url: endpoints["getMedicalOptions"],
  });
  if (success) {
    if (medical_list) {
      for (let key in medical_list) {
        arr.push({ text: medical_list[key], value: key });
      }
    }
  }
  return arr;
};

export const getMHPOptions = async () => {
  let arr1 = [];
  let arr2 = [];
  const { success, mhp, other } = await getDataWithAuth({
    api_url: endpoints["getMhpOptions"],
  });
  if (success) {
    if (mhp) {
      for (let key in mhp) {
        arr1.push({ text: mhp[key], value: key });
      }
    }

    if (other) {
      for (let key in other) {
        arr2.push({ text: other[key], value: key });
      }
    }
  }
  return {
    mhp: arr1,
    other: arr2,
  };
};

export const handleFileDownload = async (fileUrl) => {
  if (!fileUrl) return;
  let extension = getFileExtension(fileUrl);
  if (!extension) return;

  let formData = new FormData();
  formData.append("url", fileUrl);

  const { success, data } = await postDataWithAuth({
    api_url: endpoints["downloadImages"],
    data: formData,
  });

  if (success && data) {
    const link = document.createElement("a");
    link.href = data;
    link.setAttribute("download", `sbt.${extension}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }
};

export const getStatusColorCode = (status) => {
  let other_color = "#e98c40fc";
  let exited_color = "#f6033a";
  let transferred_color = "#98a408";
  let restored_color = "#00a89e";
  let produceCWC_color = "#0b7fd6";
  let registration_color = "#505a61";
  const status_mapping = {
    education: { for: "others", color_code: other_color },
    higher_education: { for: "others", color_code: other_color },
    medical_issue: { for: "others", color_code: other_color },
    mhp: { for: "others", color_code: other_color },
    referFromCwc: { for: "others", color_code: other_color },
    by_police: { for: "exited", color_code: exited_color },
    by_sbt_staff: { for: "exited", color_code: exited_color },
    by_third_party: { for: "exited", color_code: exited_color },
    dropout: { for: "exited", color_code: exited_color },
    exit_excort_restore: { for: "exited", color_code: exited_color },
    other_cci: { for: "exited", color_code: exited_color },
    reopen: { for: "transferred", color_code: transferred_color },
    transfer: { for: "transferred", color_code: transferred_color },
    transfer_to_home_state_CWC: {
      for: "transferred",
      color_code: transferred_color,
    },
    after_care: { for: "transferred", color_code: transferred_color },
    follow_up: { for: "restored", color_code: restored_color },
    rehabilitation: { for: "restored", color_code: restored_color },
    restoration: { for: "restored", color_code: restored_color },
    restoration_follow_up: { for: "restored", color_code: restored_color },
    run_away: { for: "restored", color_code: restored_color },
    short_stay: { for: "restored", color_code: restored_color },
    rescue: { for: "restored", color_code: restored_color },
    produceToCwc: { for: "produceCWC", color_code: produceCWC_color },
    registration: { for: "registration", color_code: registration_color },
  };

  let data = status_mapping[status];

  if (data?.color_code) return data.color_code;
  return other_color;
};
