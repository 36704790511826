export const UserTableHeaderFixture = [
  { text: "S No.", key: "serial_num" },
  { text: "User", key: "name" },
  { text: "Created Date Time", key: "created_at", formatDate: true },
  { text: "Action", action: true, key: ["edit", "delete"] },
];

export const filterDetailsFixtire = (data) => ({
  centertypeId: data?.centerId || "",
  role_id: "",
  name: "",
});

export const userDetailsFixture = (data) => ({
  name: "",
  email: "",
  password: "",
  status: "1",
  mobile_number: "",
  join_date: "",
  image: "",
  comment: "",
  role_id: "",
  centertypeId: data?.centerId || "",
});
