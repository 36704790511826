import React, { useEffect, useState } from "react";
import TableArea from "../../components/DataTable/TableArea";
import {
  dataForFixture,
  filterFormFixure,
  filterFormInputFixture,
} from "./utilities/fixture";
import { postDataWithAuth } from "../../_api/apiCall";
import { endpoints } from "../../_api/endpoints";
import Modal from "../../components/Modal";
import Form from "../../components/Form/Form";
import { getCenterNames, onChangeInputData } from "../../utilities/helpers";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const Manage = () => {
  const [logInUser] = useState(JSON.parse(localStorage.getItem("user")) || {});
  const [loading, setLoading] = useState(false);
  const [filterDetails, setFilterDetails] = useState(
    filterFormInputFixture(logInUser)
  );
  const [rebalititaionList, setRehabilitationList] = useState([]);
  const [paginationData, setPaginationData] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [formFixture, setFormFixture] = useState(filterFormFixure());
  const location = useLocation();
  const [dataFor, setDataFor] = useState("");
  const [tableHeaderFixture, setTableHeaderFixture] = useState([]);
  const [heading, setHeading] = useState("");
  const [filterApplied, applyFilter] = useState(false);

  const getRehabilitationList = async (page_num, filterData = false) => {
    if (loading || !dataFor) return;
    setLoading(true);
    let endpoint =
      dataFor === "short-stay-register"
        ? "getChildShortStayRegister"
        : "getRestore_Exit_Management";
    let body = filterData
      ? { status: dataFor, ...filterDetails }
      : { status: dataFor };

    if (dataFor === "short-stay-register") delete body["status"];

    const { res, success, data } = await postDataWithAuth({
      api_url: page_num ? endpoints[endpoint](page_num) : endpoints[endpoint](),
      data: body,
    });
    setLoading(false);
    if (success && data) {
      if (filterData) applyFilter(true);
      setRehabilitationList(data);
      if (openModal) setOpenModal(false);
      if (res?.pagination) setPaginationData(res.pagination);
    } else {
      if (filterData) toast.error("Something went wrong.");
    }
  };

  const handleInputChange = (e) => {
    onChangeInputData(e, filterDetails, setFilterDetails);
  };

  const handlePageSelect = (selected) => {
    if (filterApplied) getRehabilitationList(selected, filterDetails);
    else getRehabilitationList(selected);
  };

  const filterData = () => {
    if (handleValidation()) getRehabilitationList(1, filterDetails);
  };

  const handleReset = () => {
    setFilterDetails(filterFormInputFixture(logInUser));
    applyFilter(false);
    getRehabilitationList();
  };

  const handleValidation = () => {
    let valid = true;
    let from = filterDetails["from_date"];
    let to = filterDetails["to_date"];
    if (from && to && to < from) {
      valid = false;
      toast.info("From Date Should Be Less Than To Date");
    } else if ((!from && to) || (from && !to)) {
      valid = false;
      let msg = `${
        filterDetails["from_date"] ? "Select To Date" : "Select From Date"
      }`;
      toast.info(msg);
    }
    return valid;
  };

  useEffect(() => {
    async function fetchData() {
      const centerData = await getCenterNames();
      setFormFixture(filterFormFixure({ centerNames: centerData }));
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (dataFor) getRehabilitationList();
  }, [dataFor]); // eslint-disable-line

  useEffect(() => {
    setFilterDetails(filterFormInputFixture(logInUser));
    for (let i = 0; i < dataForFixture.length; i++) {
      let flag = false;
      if (Array.isArray(dataForFixture[i].link)) {
        let links = dataForFixture[i].link;
        for (let k = 0; k < links.length; k++) {
          if (links[k] === location.pathname) flag = true;
        }
      }
      if (
        flag ||
        (typeof dataForFixture[i].link === "string" &&
          dataForFixture[i].link === location.pathname)
      ) {
        setDataFor(dataForFixture[i].status_key);
        setTableHeaderFixture(dataForFixture[i].tableFixture);
        setHeading(dataForFixture[i].title);
      }
    }
  }, [location.pathname]); // eslint-disable-line

  return (
    <>
      <main className="inner">
        <TableArea
          heading={heading}
          tableHeadersFixture={tableHeaderFixture}
          data={rebalititaionList}
          loading={loading}
          startSerialNum={paginationData?.from}
          showPagination={true}
          onPageSelect={(selected) => handlePageSelect(selected)}
          paginationData={paginationData}
          perPage={paginationData?.per_page}
        >
          <div className="button-group filter-button justify-content-md-end">
            <button
              type="button"
              className="btn btn-primary btn-primary-border"
              onClick={() => {
                setOpenModal(true);
              }}
            >
              Filters
            </button>
          </div>
        </TableArea>
      </main>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        header={`${heading} Filters`}
      >
        <Form
          fixture={formFixture}
          inputs={filterDetails}
          loading={loading}
          onChange={handleInputChange}
          onSubmit={filterData}
          handleBack={handleReset}
          nextBtnText="Search"
          nextBtnClasses="btn-search"
          backBtnText="Reset"
          backBtnClasses="btn-primary-border btn-reset"
          BtnParentClasses="justify-content-end mt-3"
          showBackBtn={true}
        />
      </Modal>
    </>
  );
};

export default Manage;
