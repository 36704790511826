import React, { Fragment, useEffect, useState } from "react";
import TableArea from "../../components/DataTable/TableArea";
import Form from "../../components/Form/Form";
import {
  beneficiaryFormFixture,
  beneficiaryInputFixture,
  changeCenterFormFixture,
  changeCenterInputFixture,
  childDetailTableHeaderFixture,
  educationFormFixture,
  educationFormInputFixture,
  escortFormFixture,
  escortFormInputFixture,
  medicalFormFixture,
  medicalFormInputFixture,
  mhpFormFixture,
  mhpFormInputFixture,
  restorationFormFixture,
  restorationFormInputFixture,
  restoreDocsFormFixture,
  restoreDocsInputFormFixture,
  transferDatelabels,
  transferFormFixture,
  transferFormInputFixture,
} from "./utilities/fixtures";
import { getDataWithAuth, postDataWithAuth } from "../../_api/apiCall";
import { toast } from "react-toastify";
import { endpoints } from "../../_api/endpoints";
import { onChangeInputData } from "../../utilities/helpers";
import validate from "../../utilities/validation";
import Modal from "../../components/Modal";

const MoreDetails = ({
  title = "",
  childInfo = {},
  centerNames = [],
  tagNames = [],
  getChildInfo = () => {},
  isViewMode = true,
  isEditAllowed = false,
  documentPath = "",
  audioPath = "",
  openModal = false,
  setOpenModal = () => {},
  setTitle = () => {},
}) => {
  const [logInUser] = useState(JSON.parse(localStorage.getItem("user")) || {});
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formInputs, setFormInputs] = useState({});
  const [formFixture, setFormFixture] = useState([]);
  const [docs, setDocs] = useState([restoreDocsInputFormFixture]);
  const [addFormFixture, setAddFormFixture] = useState([]);
  const [addFormInputs, setAddFormInputs] = useState({});

  const handleInputChange = (e, inputs, setInputs) => {
    let { name, value } = e.target;
    if (title === "Escort/Exit" && name === "exit_type") {
      setFormFixture(
        escortFormFixture({ centerNames: centerNames, exit_type: value })
      );
    }
    onChangeInputData(e, inputs, setInputs);
  };

  const getTableData = async (endpoint, id, listingFor) => {
    if (loading || !id) return;
    setLoading(true);
    const { success, data } = await getDataWithAuth({
      api_url: listingFor
        ? endpoints[endpoint](listingFor, id)
        : endpoints[endpoint](id),
    });
    setLoading(false);
    if (success) {
      setTableData(data);
    } else {
      toast.error("Something went wrong!");
    }
  };

  const saveData = async (endpoint) => {
    if (loading || !childInfo?.data?.id) return;
    setLoading(true);
    let formData = new FormData();
    let id = childInfo?.data?.id;
    formData.append("childregId", id);
    let ind = 0;

    if (
      ["Restore", "Restoration Follow-Up", "Transfer", "Escort/Exit"].includes(
        title
      )
    ) {
      for (let key in docs) {
        const doc = docs[key];
        if (!doc["id"]) {
          for (let key2 in doc) {
            formData.append(`documents[${ind}][${key2}]`, doc[key2]);
          }
          ind++;
        }
      }
    }

    for (let key in formInputs) {
      if (title === "Transfer" && key === "current_centerId") continue;
      if (formInputs[key]) formData.append(key, formInputs[key]);
    }

    const { success, message } = await postDataWithAuth({
      api_url: endpoints[endpoint],
      data: formData,
      contentType: "multipart/form-data",
    });
    setLoading(false);
    if (success) {
      if (message) toast.success(message);
      getChildInfo(
        childInfo?.data?.id,
        childInfo?.action,
        title === "Transfer" || title === "Escort/Exit"
          ? "Child’s Information"
          : title
      );
      getData(title);
      setDocs([restoreDocsInputFormFixture]);
    } else {
      toast.error("Something went wrong!");
    }
  };

  const handleSubmit = () => {
    if (!loading && handleValidation()) {
      if (title === "Restore") {
        saveData("saveRestoration");
      } else if (title === "Restoration Follow-Up") {
        saveData("saveRestoration_follow_up");
      } else if (title === "Medical") {
        saveData("saveMedicalConsent");
      } else if (title === "Education") {
        saveData("saveEducation2");
      } else if (title === "MHP") {
        saveData("saveMHP");
      } else if (title === "Transfer") {
        saveData("saveTransfer");
      } else if (title === "Escort/Exit") {
        saveData("saveEscortExit");
      } else if (title === "Centers") {
        saveData("changeCenter");
      }
    }
  };

  const handleValidation = () => {
    let validation = true;
    const transferDateLabel = transferDatelabels(title);
    for (let key in formInputs) {
      let err =
        key === "transfer_date"
          ? validate[key](formInputs[key], transferDateLabel)
          : validate[key](formInputs[key]);
      err =
        title === "Transfer" && key === "centerId"
          ? validate[key](formInputs[key], "Transfer To Center")
          : err;

      if (title === "Education" && key === "education_level") err = "";
      if (title === "MHP" && key === "other") err = "";

      if (!!err) {
        toast.error(err);
        return false;
      }
    }

    if (["Restoration Follow-Up", "Transfer", "Escort/Exit"].includes(title)) {
      for (let i = 0; i < docs.length; i++) {
        for (let key in docs[i]) {
          let err = validate[key](docs[i][key]);
          if (!!err) {
            toast.error(err);
            return false;
          }
        }
      }
    }

    return validation;
  };

  const getData = (dataFor) => {
    switch (dataFor) {
      case "Activity Log":
        setFormFixture([]);
        setFormInputs({});
        getTableData("getActivityLogs", childInfo?.data?.id);
        break;
      case "Documents":
        setFormFixture([]);
        setFormInputs({});
        getTableData("getDocumentList", childInfo?.data?.id);
        break;
      case "Centers":
        setFormFixture(changeCenterFormFixture({ centerNames: centerNames }));
        setFormInputs(changeCenterInputFixture(logInUser));
        getTableData("centersHistory", childInfo?.data?.id);
        break;
      case "Restore":
      case "Restoration Follow-Up":
        let status =
          dataFor === "Restore" ? "restoration" : "restoration_follow_up";
        setFormFixture(
          restorationFormFixture({ centerNames: centerNames }, status)
        );
        setFormInputs(
          restorationFormInputFixture(childInfo?.data?.centerdetailId)
        );
        setDocs([restoreDocsInputFormFixture]);

        getTableData("allStatusListing", childInfo?.data?.id, status);
        break;
      case "Medical":
        setFormFixture(
          medicalFormFixture({
            centerNames: centerNames,
            medicalIssues: [],
          })
        );
        setFormInputs(medicalFormInputFixture(logInUser));
        getTableData("allStatusListing", childInfo?.data?.id, "medical_issue");
        break;
      case "Education":
        setFormFixture(
          educationFormFixture({
            centerNames: centerNames,
          })
        );
        setFormInputs(educationFormInputFixture(logInUser));
        getTableData("allStatusListing", childInfo?.data?.id, "education");
        break;
      case "MHP":
        setFormFixture(
          mhpFormFixture({
            centerNames: centerNames,
          })
        );
        setFormInputs(mhpFormInputFixture(logInUser));
        getTableData("allStatusListing", childInfo?.data?.id, "mhp");
        break;
      case "Transfer":
        setFormFixture(transferFormFixture({ centerNames: centerNames }));
        setFormInputs(
          transferFormInputFixture(childInfo?.data?.centerdetailId)
        );
        break;
      case "Escort/Exit":
        setFormFixture(escortFormFixture({ centerNames: centerNames }));
        setFormInputs(escortFormInputFixture(childInfo?.data?.centerdetailId));
        break;
      default:
        setFormFixture([]);
        setFormInputs({});
        setTableHeaders([]);
        break;
    }
  };

  const handleDocInputChange = (e, ind) => {
    onChangeInputData(e, docs, setDocs, {
      index: ind,
    });
  };

  const addMoreDocuments = () => {
    let c1 = 0;
    for (let i = 0; i < docs.length; i++) {
      if (docs[i]["tagId"] && docs[i]["filename"] && docs[i]["doc_date"]) c1++;
    }
    if (c1 === docs.length) setDocs([...docs, restoreDocsInputFormFixture]);
  };

  const handleDelete = async (id) => {
    if (loading || !id) return;
    let endpoint =
      title === "Education"
        ? "deleteEducation"
        : title === "Medical"
        ? "deleteMedical"
        : title === "MHP"
        ? "deleteMhp"
        : title === "Documents"
        ? "deleteDocuemnts"
        : title === "Activity Log"
        ? "deleteActivityLogs"
        : title === "Restoration Follow-Up"
        ? "listingDelete"
        : "";
    if (!endpoint) return;
    setLoading(true);
    const { success } = await getDataWithAuth({
      api_url: endpoints[endpoint](id),
    });
    setLoading(false);
    if (success) {
      toast.success(`${title} deleted successfully`);
      setTableData((prev) => prev.filter((item) => item.id !== id));
      getChildInfo(childInfo?.data?.id, childInfo?.action, title);
    } else {
      toast.error("Something went wrong!");
    }
  };

  const handleAdd = async () => {
    if (!childInfo?.data?.id || !childInfo?.data?.centerdetailId) return;
    if (!loading && validateAdd()) {
      let endpoint = "";
      let action = addFormInputs["id"] ? "update" : "save";

      endpoint =
        title === "Education"
          ? `${action}Education`
          : title === "Medical"
          ? `${action}Medical`
          : title === "MHP"
          ? `${action}Mhp`
          : "";

      if (!endpoint) return;
      let body = { ...addFormInputs };
      if ("register_type" in body) delete body["register_type"];
      let formData = new FormData();
      for (let key in body) {
        formData.append(key, body[key]);
      }
      formData.append("centerId", childInfo.data.centerdetailId);
      formData.append("childregId", childInfo.data.id);

      setLoading(true);
      const { success, message } = await postDataWithAuth({
        api_url: endpoints[endpoint],
        data: formData,
        contentType: "multipart/form-data",
      });
      setLoading(false);
      if (success) {
        if (message) toast.success(message);
        getData(title);
        setOpenModal(false);
        getChildInfo(childInfo?.data?.id, childInfo?.action, title);
      } else {
        if (message) toast.error(message);
        else toast.error("Something went wrong!");
      }
    }
  };

  const cancelAdd = () => {
    setAddFormInputs(beneficiaryInputFixture(title));
    setOpenModal(false);
  };

  const validateAdd = () => {
    let validation = true;

    for (let key in addFormInputs) {
      let err = validate[key](addFormInputs[key]);
      if (!!err) {
        toast.error(err);
        return false;
      }
    }

    return validation;
  };

  const handleEdit = (rowData) => {
    let obj = { ...addFormInputs };
    for (let key in addFormInputs) {
      if (key in rowData) obj[key] = rowData[key];
    }
    obj["id"] = rowData["id"];
    setAddFormInputs(obj);
    setOpenModal(true);
  };

  const handleDeleteDocuments = async (index) => {
    if (index) {
      setDocs((prev) => prev.filter((_, ind) => index !== ind));
      return;
    }
  };

  useEffect(() => {
    let header_fixture = childDetailTableHeaderFixture(title);
    setTableHeaders(header_fixture);
    setTableData([]);
    getData(title);
    setAddFormFixture(beneficiaryFormFixture(title));
    setAddFormInputs(beneficiaryInputFixture(title));
    setDocs([restoreDocsInputFormFixture]);
  }, [title]); // eslint-disable-line

  return (
    <>
      {!!formFixture.length && !isViewMode && isEditAllowed && (
        <div className="tab-content p-4" id="pills-tabContent">
          <div className="registration-form">
            <Form
              fixture={formFixture}
              inputs={formInputs}
              loading={loading}
              onChange={(e) => handleInputChange(e, formInputs, setFormInputs)}
              onSubmit={handleSubmit}
              nextBtnText="Submit"
              nextBtnClasses="btn-search"
              showNextBtn={
                ![
                  "Restore",
                  "Restoration Follow-Up",
                  "Transfer",
                  "Escort/Exit",
                ].includes(title)
              }
              backBtnClasses="btn-primary-border btn-reset"
            ></Form>

            {[
              "Restore",
              "Restoration Follow-Up",
              "Transfer",
              "Escort/Exit",
            ].includes(title) ? (
              <div>
                <div className="col-12 col-md-4 mb-4">
                  <h5 className="mb-3 mt-2 mt-md-0 mb-md-0 page-title">
                    Upload Documents
                  </h5>
                </div>
                {docs.map((docInput, key) => (
                  <Fragment key={key}>
                    <Form
                      fixture={restoreDocsFormFixture({ tagNames: tagNames })}
                      inputs={docInput}
                      loading={loading}
                      onChange={(e) => handleDocInputChange(e, key)}
                      onSubmit={handleSubmit}
                      nextBtnText="Submit"
                      nextBtnClasses="btn-search"
                      showNextBtn={key === docs.length - 1}
                      showBackBtn={key === docs.length - 1}
                      backBtnText="Add More"
                      backBtnClasses="btn-add"
                      handleBack={addMoreDocuments}
                      showDeleteBtn={key !== 0}
                      handleDelete={() => handleDeleteDocuments(key)}
                    ></Form>
                  </Fragment>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      )}

      <TableArea
        tableHeadersFixture={tableHeaders}
        data={tableData}
        loading={loading}
        showNotFound={tableHeaders.length > 0}
        filePath={documentPath}
        audioFilePath={audioPath}
        onDelete={(rowData) => handleDelete(rowData["id"])}
        onEdit={(rowData) => handleEdit(rowData)}
        deleteLastOnly={title === "Activity Log" || title === "Centers"}
        hideAction={childInfo?.action === "view"}
      ></TableArea>

      <Modal
        open={openModal && !loading}
        onClose={cancelAdd}
        header={`${addFormInputs["id"] ? "Edit" : "Add"} ${title}`}
      >
        <div className="btn-gap-fix">
          <Form
            fixture={addFormFixture}
            inputs={addFormInputs}
            loading={loading}
            onChange={(e) =>
              handleInputChange(e, addFormInputs, setAddFormInputs)
            }
            onSubmit={handleAdd}
            handleBack={cancelAdd}
            showBackBtn={true}
            backBtnText="Close"
            backBtnClasses="btn-primary-border"
            nextBtnText={addFormInputs["id"] ? "Update" : "Save"}
            BtnParentClasses="justify-content-end mt-3"
          />
        </div>
      </Modal>
    </>
  );
};

export default MoreDetails;
